<template>
  <div class="flex mt-3">
    <div class="w-full mb-3 pt-0">
      <label
        class="hidden sm:block text-md font-bold ml-2 mb-2"
        :class="{
          ...formCssClasses('label'),
        }"
      >
        Password
      </label>
      <!-- Mobile Version -->
      <input
        type="text"
        @blur="onBlurPassword"
        :class="{
          'border-2 border-red-500': errors.password,
          ...formCssClasses('input'),
        }"
        v-model="password"
        placeholder="Type your password"
        class="sm:hidden px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none outline-red-100 focus:outline-red-200 w-full"
      />
      <!-- End Mobile Version -->

      <!-- Desktop Version -->
      <input
        type="text"
        @blur="onBlurPassword"
        :class="{
          'border-2 border-red-500': errors.password,
          ...formCssClasses('input'),
        }"
        v-model="password"
        placeholder="Type your password"
        class="hidden sm:block px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
      />
      <!-- End Desktop Version -->

      <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">
        {{ errors.password }}
      </p>
      <div class="mt-10 mb-0">
        <button
          @click="onCreateAccount"
          type="button"
          class="w-full bg-green-500 hover:bg-green-600 focus:outline-none text-white font-bold px-3 py-4 rounded"
          :disabled="loading"
        >
          <div class="flex justify-center">
            <moon-loader
              :loading="loading"
              color="#FFF5F5"
              size="25px"
            ></moon-loader>
            <p v-if="!loading">Create account</p>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { MoonLoader } from "vue-spinner/dist/vue-spinner.min.js";

export default {
  components: {
    MoonLoader,
  },
  data: () => ({
    password: "",
    errors: {
      password: "",
    },
    loading: false,
    isValid: false,
  }),
  computed: {
    ...mapState("courseInformation", ["courseTypeId"]),
    ...mapGetters(["formCssClasses"]),
  },
  methods: {
    ...mapActions("courseInformation", ["createStudentAccount"]),
    async onCreateAccount() {
      this.errors.password = "";
      // setTimeout(()=>{
      //   console.log('ddd');
      // },5000)
      // Object.keys(this.errors).forEach( error => {
      //   console.log(error);
      //   return;
      //   if(!error){
      //     this.errors[error] = 'Password is required';
      //     return;
      //   }
      // })
      // for (const error of Object.keys(this.errors)) {
      //   console.log(this.errors[error]);
      //   if(!this.errors[error]){
      //     return;
      //   }
      // }
      if (!this.password) {
        this.errors.password = "Password is required";
        return;
      }

      if (this.password.length < 6) {
        this.errors.password = "Password minimum length 6 characters.";
        return;
      }

      this.loading = true;
      await this.createStudentAccount({ password: this.password });
      /*  */

      // document.location.href = `${
      //   process.env.VUE_APP_STUDENT_PORTAL_URL
      // }/instant_login?h=${btoa(this.$store.state.formData.email)}`;
      this.loading = false;
    },
    onBlurPassword() {
      const value = this.password;
      this.errors.password = "";
      //console.log(value);
      if (!value) {
        this.errors.password = "Password is required";
      }

      if (value.length < 6) {
        this.errors.password = "Password minimum length 6 characters.";
      }
    },
  },
};
</script>

<style></style>
