<template>
  <div class="w-full p-6 font-bold text-lg text-green-500 border-green-400 border rounded text-center mt-5">
    <p class="font-bold">Thank you. Your account has been created.</p>
    <a
      :href="`https://students.phlebotomyusa.com/login`"
      class="text-blue-500 underline font-bold"
    >
      Click here to access it
    </a>
  </div>
</template>

<script>
  export default {

  }
</script>

<style>

</style>