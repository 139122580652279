<template>
  <div>
    <div class="flex items-end mt-2 md:mt-4">
      <div
        class="w-2/12 mb-2 text-center"
        style="border-bottom: 1px solid black;"
      >
        <div
          class="w-auto h-full bg-yellow-300 mb-1 cursor-pointer"
          v-if="!sign"
          @click="onCheck(item.name, true)"
        >
            <span class="text-sm md:text-base lg:text-base xl:text-base">
              Initials
              <zondicon
              icon="arrow-thick-down"
              class="h-5 ml-4 sm:ml-10 md:ml-8"/>
            </span>
        </div>
        <span
          class="student-signature"
          v-if="sign"
        >
          {{ getInitials }}
        </span>
      </div>
      <div class="w-9/12 mb-2 px-1">
        <validation-provider :name="item.name" v-slot="{ errors }">
          <label class="text-gray-800 text-md font-bold mb-2">
            <input
              class="mr-2"
              type="checkbox"
              v-model="sign"
              style="display: none;"
            >
            <span class="text-xs md:text-sm">
              {{ item.text }}
            </span>
          </label>
          <p class="mt-1 text-red-500 text-xs md:text-sm font-semibold italic">{{ errors[0]}}</p>
        </validation-provider>
      </div>
      <div class="w-1/12 mb-2 pt-0">
      </div>
    </div>
  </div>
</template>

<script>
  import Zondicon from 'vue-zondicons';
  import { ValidationProvider} from 'vee-validate/dist/vee-validate.full.esm';

  export default {
    props:['item', 'sign', 'getInitials', 'on-check'],
    components:{
      'zondicon': Zondicon,
      ValidationProvider
    },
    data: () => ({}),
    methods: {
      // onSign(){
      //   this.$emit('on-sign');
      // }
    }
  }
</script>

<style scoped>
  span.student-signature{
    font-family: "Cedarville Cursive";
    /*font-size:12pt;*/
  }
  .cursor-pointer{
    cursor: pointer;
  }

</style>