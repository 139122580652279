<template>
  <div>
    <div class="w-full mb-3 pt-0">
      <label
        class="block text-md font-bold ml-2 mb-4 sm:mb-2"
        :class="{
          ...formCssClasses('label'),
        }"
      >
        Card Type
      </label>
      <!-- <div class="grid grid-cols-2 text-red-900 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full">
      <div class="border-red-200 border-b-2">
        <label class="inline-flex items-center w-full md:6/12">
          <input
            type="radio"
            class="form-radio mx-6 my-5 h-6 w-6"
            :class="getBillingRadioBtnColor"
            name="cardType"
            v-model="billingCardType"
            value="credit"
          >
          <span class="ml-3 text-md">Credit Card</span>
        </label>
      </div>
      <div>
        <label class="inline-flex items-center w-full md:6/12">
          <input
            type="radio"
            class="form-radio mx-6 my-5 h-6 w-6"
            :class="getBillingRadioBtnColor"
            name="cardType"
            v-model="billingCardType"
            value="debit"
          >
          <span class="ml-3 text-md">Debit Card</span>
        </label>
      </div>
    </div> -->
      <div
        class="grid grid-cols-1 md:grid-cols-2 text-red-900 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
      >
        <!-- Actual Radio Buttons -->
        <div class="border-red-200 border-b-2  md:border-r-2 md:border-b-0">
          <label class="inline-flex items-center w-full md:6/12">
            <input
              type="radio"
              class="form-radio mx-6 my-5 h-6 w-6"
              :class="{
                ...formCssClasses('radio_button'),
              }"
              name="cardType"
              v-model="billingCardType"
              value="credit"
            />
            <span
              class="ml-3 text-md"
              :class="{
                ...formCssClasses('label'),
              }"
            >
              Credit Card
            </span>
          </label>
        </div>
        <div>
          <label class="inline-flex items-center w-full md:6/12">
            <input
              type="radio"
              class="form-radio mx-6 my-5 h-6 w-6"
              :class="{
                ...formCssClasses('radio_button'),
              }"
              name="cardType"
              v-model="billingCardType"
              value="debit"
            />
            <span
              class="ml-3 text-md"
              :class="{
                ...formCssClasses('label'),
              }"
            >
              Debit Card
            </span>
          </label>
        </div>
        <!-- End Actual Radio Buttons -->
      </div>
    </div>
    <!-- Number, Exp and CVC -->
    <div class="md:flex mt-8">
      <div class="md:w-7/12 mb-3 md:mr-6 pt-0">
        <label
          class="hidden sm:block text-md font-bold ml-2 mb-2"
          :class="{
            ...formCssClasses('label'),
          }"
        >
          Card Number
        </label>
        <!-- rules="required|numeric|min:15|max:16" -->
        <validation-provider
          name="card number"
          :rules="validationRules('card_number')"
          v-slot="{ errors }"
        >
          <div class="flex">
            <!-- Mobile Version -->
            <input-facade
              mask="#### #### #### ####"
              type="text"
              v-model="billingNumber"
              :class="{
                'border-2 border-red-500': errors.length != 0,
                ...formCssClasses('input'),
              }"
              placeholder="Card Number"
              class="sm:hidden px-3 py-4 placeholder-pink-400 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
            />
            <!-- End Mobile Version -->

            <!-- Desktop Version -->
            <input-facade
              mask="#### #### #### ####"
              type="text"
              v-model="billingNumber"
              :class="{
                'border-2 border-red-500': errors.length != 0,
                ...formCssClasses('input'),
              }"
              placeholder="XXXX XXXX XXXX XXXX"
              class="hidden sm:block px-3 py-4 placeholder-red-300 text-red-900 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
            />
            <!-- End Desktop Version -->

            <!-- interactive credit card icon changer -->
            <img
              v-if="billingNumber.length > 10"
              class="-ml-16 h-8 z-0 mt-3 fill-current text-red-700"
              :src="require(`payment-icons/min/mono/${cardType.icon}.svg`)"
              alt=""
            />
          </div>
          <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">
            {{ errors[0] }}
          </p>
        </validation-provider>
      </div>

      <div class="md:w-3/12 mb-3 md:mr-6 pt-3 md:pt-0">
        <label
          class="hidden sm:block text-md font-bold ml-2 mb-2"
          :class="{
            ...formCssClasses('label'),
          }"
        >
          Exp.
        </label>

        <validation-provider
          name="expiration"
          :rules="validationRules('card_exp')"
          v-slot="{ errors }"
        >
          <!-- Mobile Version -->
          <input-facade
            mask="## / ##"
            type="text"
            v-model="exp"
            :class="{
              'border-2 border-red-500': errors.length != 0,
              ...formCssClasses('input'),
            }"
            placeholder="Exp  (mm/yy)"
            class="sm:hidden px-3 py-4 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
          />
          <!-- End Mobile Version -->

          <!-- Desktop Version -->
          <input-facade
            mask="## / ##"
            type="text"
            v-model="exp"
            :class="{
              'border-2 border-red-500': errors.length != 0,
              ...formCssClasses('input'),
            }"
            placeholder="MM / YY"
            class="hidden sm:block px-3 py-4 placeholder-red-300 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
          />
          <!-- End Desktop Version -->

          <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">
            {{ errors[0] }}
          </p>
        </validation-provider>
      </div>

      <div class="md:w-2/12 mb-3 pt-3  md:pt-0">
        <label
          class="hidden sm:block text-md font-bold ml-2 mb-2"
          :class="{
            ...formCssClasses('label'),
          }"
        >
          CVC
        </label>

        <validation-provider
          name="cvc"
          :rules="validationRules('card_cvc')"
          v-slot="{ errors }"
        >
          <!-- Mobile Version -->
          <input-facade
            mask="####"
            type="text"
            v-model="code"
            :class="{
              'border-2 border-red-500': errors.length != 0,
              ...formCssClasses('input'),
            }"
            placeholder="CVC"
            class="sm:hidden px-3 py-4 relative bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
          />
          <!-- End Mobile Version -->

          <!-- Desktop Version -->
          <input-facade
            mask="####"
            type="text"
            v-model="code"
            :class="{
              'border-2 border-red-500': errors.length != 0,
              ...formCssClasses('input'),
            }"
            placeholder=""
            class="hidden sm:block px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
          />
          <!-- End Desktop Version -->

          <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">
            {{ errors[0] }}
          </p>
        </validation-provider>
      </div>
    </div>
    <!-- End Number, Exp and CVC -->

    <!-- Name on Card -->
    <div class="flex mt-6">
      <div class="w-full mb-3 pt-0">
        <label
          class="hidden sm:block text-md font-bold ml-2 mb-2"
          :class="{
            ...formCssClasses('label'),
          }"
        >
          Name on Card
        </label>

        <validation-provider
          name="name"
          :rules="validationRules('card_name')"
          v-slot="{ errors }"
        >
          <!-- Mobile Version -->
          <input
            type="text"
            v-model="billingName"
            placeholder="Name on Card"
            :class="{
              'border-2 border-red-500': errors.length != 0,
              ...formCssClasses('input'),
            }"
            class="sm:hidden px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
          />
          <!-- End Mobile Version -->

          <!-- End Desktop Version -->
          <input
            type="text"
            v-model="billingName"
            placeholder="John Doe"
            :class="{
              'border-2 border-red-500': errors.length != 0,
              ...formCssClasses('input'),
            }"
            class="hidden sm:block px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full"
          />
          <!-- End Desktop Version -->

          <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">
            {{ errors[0] }}
          </p>
        </validation-provider>
      </div>
    </div>
    <!-- End Name on Card -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { InputFacade } from "vue-input-facade";
import {
  ValidationProvider,
  extend,
} from "vee-validate/dist/vee-validate.full.esm";

extend("notExp", (value) => {
  return false;
  // return new Date(this.explodedExp).valueOf() > new Date().valueOf();
});
export default {
  components: {
    "input-facade": InputFacade,
    "validation-provider": ValidationProvider,
  },
  computed: {
    ...mapGetters("formData", ["cardType", "explodedExp"]),
    ...mapState("courseInformation", ["courseTypeId", "tnApplicationFee"]),
    ...mapGetters("courseInformation", ["remainingBalance"]),
    ...mapGetters(["formCssClasses"]),
    billingCardType: {
      get() {
        return this.$store.state.formData.billingCardType;
      },
      set(value) {
        this.$store.commit("formData/updateBillingCardType", value);
      },
    },
    billingName: {
      get() {
        return this.$store.state.formData.billingName;
      },
      set(value) {
        this.$store.commit("formData/updateBillingName", value);
      },
    },
    billingNumber: {
      get() {
        return this.$store.state.formData.billingNumber;
      },
      set(value) {
        this.$store.commit("formData/updateBillingNumber", value);
      },
    },
    exp: {
      get() {
        return this.$store.state.formData.exp;
      },
      set(value) {
        this.$store.commit("formData/updateExp", value);
      },
    },
    code: {
      get() {
        return this.$store.state.formData.code;
      },
      set(value) {
        this.$store.commit("formData/updateCode", value);
      },
    },
    getBillingRadioBtnColor() {
      if (this.courseTypeId === 4) {
        return "focus:outline-blue-100 text-blue-400";
      } else if (this.courseTypeId === 11) {
        return "focus:outline-red-100 text-red-400";
      }
      return "focus:outline-red-100 text-red-400";
      // return this.courseTypeId == 4 ?
      // 'focus:outline-blue-100 text-blue-400' :
      // 'focus:outline-red-100 text-red-400';
    },
    cardNumberRules() {
      // numeric|min:15|max:16
      if (this.remainingBalance > 0)
        return {
          required: true,
          numeric: true,
          min: 15,
          max: 16,
        };
      return {};
    },
    expCardRules() {},
  },
  methods: {
    validationRules(type) {
      if (this.remainingBalance > 0 && type === "card_number")
        return {
          required: true,
          numeric: true,
          min: 15,
          max: 16,
        };
      if (this.remainingBalance > 0 && type === "card_name")
        return {
          required: true,
        };
      if (this.remainingBalance > 0 && type === "card_exp")
        return {
          required: true,
          numeric: true,
          //min: 4,
        };
      if (this.remainingBalance > 0 && type === "card_cvc")
        return {
          required: true,
          numeric: true,
          min: 3,
          max: 4,
        };

      return {};
    },
  },
  // mounted: {
  //
  // }
};
</script>

<style scoped></style>
