<template>
<div class="lg:flex lg:justify-center lg:justify-start p-2 px-12 lg:p-6" :class="getBGColor">
    <div class="text-white">
        <div class="flex justify-center">
          <p class="lg:hidden items-center block text-base text-red-100 text-white truncate">
            {{ name }}
          </p>
        </div>
      <a class="lg:hidden flex justify-center text-red-100 text-base text-white">
        {{ title }}
      </a>
      <a class="hidden lg:inline-block text-2xl text-white mr-10">
          {{ name }} {{ title }}
      </a>

    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  props: ['title'],
  data: () => ({

  }),
  mounted(){
    this.$nextTick(()=>{
      //console.log('Course Id', this.courseTypeId);
    });
  },
  computed: {
    ...mapState('courseInformation', [
      'name',
      'courseTypeId',
      'courseType',
    ]),
    getBGColor(){
      switch(this.courseTypeId) {
        case 4:
          return 'bg-blue-800';
        case 3:
          return 'bg-red-800';
        case 11:
          return 'bg-green-ecg';
        default:
          return 'bg-red-800';
      }
      //return this.courseTypeId == 4 ? 'bg-blue-800' : ( this.courseTypeId === 3 ? 'bg-green-ecg' : 'bg-red-800');
    }
  }
}
</script>

<style>

</style>
