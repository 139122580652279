<template>
<div>
  <h4 class="text-xl sm:text-3xl font-normal leading-normal mt-2 text-red-900">
    {{ formTitle }}
  </h4>

  <hr class="border-red-300 mt-5">


   <!-- <h3 class="text-xl font-normal leading-normal mt-8 text-red-900">
    Can you provide Official High School Transcripts or College Transcripts?
  </h3> -->
  <h3 class="text-xl font-normal leading-normal mt-2 text-red-900">
    Please enter your email to verify if we have received your documents.
  </h3>
<!--
  <div class="mt-2">
    <a class="hover:text-blue-700 text-blue-500 underline font-bold" href="https://www.phlebotomyusa.com/wp-content/uploads/2020/01/Tennessee-PDF.pdf" target="_blank">Guide to complete your registration and begin class</a>
  </div> -->


  <div class="flex flex-col mt-4">
    <div class="flex-auto mb-3 pt-0">
      <label class="text-red-900 text-md font-bold mb-4">
        Enter your email
      </label>
      <input
        type="text"
        :class="{ 'border-2 border-red-500' : errors.email }"
        :value="email"
        @input="onInput('email', $event.target.value)"
        placeholder="example@gmail.com"
        class="px-3 py-4 placeholder-red-300 text-red-900 relative bg-white outline-none rounded text-base shadow  focus:outline-red-200 w-full "
        :disabled="isApproved"
      />
      <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic">{{ errors.email }}</p>
    </div>
    <div
      class="flex-grow-0 mb-3 pt-0"
      v-if="message"
    >
      <div
        class="border-2 px-4 py-3 rounded relative"
        role="alert"
        :class="[success ? `bg-green-200 border-green-500` : `bg-red-200 border-red-500`]"
      >
        <p
          class=" font-bold mr-2"
          :class="[success ? `text-green-700` : `text-red-700`]"
        >
          {{ message }}
        </p>
        <span @click="message = ``" class="absolute top-0 bottom-0 right-0 px-4 py-3">
          <svg
            class="fill-current h-6 w-6"
            :class="[success ? `text-green-500` : `text-red-500`]"
            role="button" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
          /></svg>
        </span>
      </div>
    </div>
    <div class="flex-grow-0 mb-3 pt-0 text-center" v-if="!isApproved">
      <button
        type="button"
        class="focus:outline-none text-white font-bold py-3 px-8 rounded bg-red-800 hover:bg-red-900"
        @click="onVerifyStudent"
        :disabled="isProcessing"
      >
        <div class="flex justify-center">
          <moon-loader :loading="isProcessing" color="#FFF5F5" size="25px"></moon-loader>
          <span v-if="!isProcessing">
            Verify
          </span>
        </div>

      </button>
    </div>
    <div
      class="flex-grow-0 mb-3 pt-0 text-center"
      v-if="isApproved"
    >
      <button
        type="button"
        class="focus:outline-none text-white font-bold py-3 px-8 rounded bg-red-800 hover:bg-red-900"
        @click="clickYes"
      >
        <div class="flex justify-center">
          <moon-loader :loading="isProcessing" color="#FFF5F5" size="25px"></moon-loader>
          <span v-if="!isProcessing">
            Click here to continue
          </span>
        </div>
      </button>
    </div>
    <!-- Buttons
    <button @click="clickYes" :class="[ yes ? 'bg-red-300 font-semibold border-red-500': 'bg-red-100 hover:bg-red-200 border-red-200 hover:border-red-300' ]" class=" focus:outline-none border-2 px-8 py-4 mb-3 mr-6 rounded-lg ">
      <h5 :class="[ yes ? 'text-green-900': 'text-red-900' ]" class=" text-lg leading-tight truncate">YES</h5>
    </button>


     Mobile Button
    <button @click="showAlert" :class="[ no ? 'bg-red-300 font-semibold border-red-500': 'bg-red-100 hover:bg-red-200 border-red-200 hover:border-red-300' ]" class="lg:hidden focus:outline-none border-2 px-8 py-4 mb-3 mr-6 rounded-lg ">
      <h5 :class="[ no ? 'text-green-900': 'text-red-900' ]" class="text-lg leading-tight truncate">NO</h5>
    </button>
    End  Mobile Button

    Desktop Button
    <button @click="clickNo" :class="[ no ? 'bg-red-300 font-semibold border-red-500': 'bg-red-100 hover:bg-red-200 border-red-200 hover:border-red-300' ]" class="hidden lg:inline-block focus:outline-none border-2 px-8 py-4 mb-3 mr-6 rounded-lg ">
      <h5 :class="[ no ? 'text-green-900': 'text-red-900' ]" class="text-lg leading-tight truncate">NO</h5>
    </button>
    <End Desktop Button
    End Buttons -->
  </div>



  <div class="mx-6 mb-32 lg:mb-0 text-red-800">
    <!-- <p class="mt-6">
      The <span class="font-bold">Tennessee Higher Education Commission</span> rule 1540-01-02.12 and 1540-01-02.15 <span class="font-bold">requires</span> students prior to commencement of class to submit an <span class="font-bold">official transcript from the high school, GED score sheet </span>from the appropriate issuing entity, or an <span class="font-bold">official military document </span>indicating that the student completed high school such as an Enlisted Record Brief.
    </p> -->
    <p class="mt-6">
      Students are required to have a <span class="font-bold">high school diploma</span> or <span class="font-bold">high school equivalency</span> as the minimum entry requirement into the course. In addition, students must also submit a copy of <span class="font-bold">EMT, CAN, MA, or CPR/BLS</span> certification.
    </p>
  </div>


</div>
</template>

<script>
import { MoonLoader } from 'vue-spinner/dist/vue-spinner.min.js'
import SideMessage from '@/components/Checkout/SideBar/Elements/SideMessage.vue'
import TNMessage from '@/components/Checkout/SideBar/Elements/Messages/TNMessage.vue'

import { mapState, mapActions } from 'vuex'

export default {
  components: {
    'side-message' : SideMessage,
    'tn-message' : TNMessage,
    MoonLoader,
  },
  data: () => ({
    formTitle: `Required documentation`,
    email: ``,
    errors: {
      email: ``,
    },
    message: ``,
    success: false,
    regex: {
      email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    },
    yes: false,
    no: false
  }),
  created(){
    if(this.$route.query.email){
      this.email = this.$route.query.email;
      this.onVerifyStudent();
    }
    //console.log(this.$route.query);
  },
  computed: {
    ...mapState('formData', [
      'firstTest',
      //'email'
    ]),
    ...mapState('txApplicant', ['isProcessing', 'isApproved']),
    //...mapState('checkoutData',['']),
  },
  methods: {
    ...mapActions('txApplicant', ['verifyStudent', 'verifyStudentEmail']),
    onInput(field, value){
      this.errors[field] = ``;
      if(!value){
        this.errors[field] = `Field is required`;
      }

      if(field === `email`){
        this.errors.email = !this.regex.email.test(value) ? `The email field must be valid` : ``;
      }

      this[field] = value;
    },
    async onVerifyStudent(){
      this.message = ``;
      this.$store.commit('formData/updateFirstTest', true);
      if(this.errors.email){
        return;
      }

      if(!this.email){
        this.errors.email = `Field is required`;
        return;
      }

      const post = {
        email: this.email,
      }
      const data = await this.verifyStudent({post});
      if(!data.success){
        this.success = data.success;
        this.message = `You Application was not found. Please call ${process.env.VUE_APP_PHLEBS_PHONE_NUMBER} if you have any questions`;
        return;
      }

      if(this.isApproved){
        this.success = data.success;
        this.formTitle = `Your documents were approved`;
        this.message = `Your documents were approved.`;
      }

      if(!this.isApproved){
        this.success = false;
        this.message = `Your documents have not been approved yet. We will contact you as soon as they are approved.`;
        this.clickNo();
        return;
      }

      console.log(data);
    },
    showAlert() {
      // Use sweetalert2
      this.clickNo();
      this.$swal('Thank you for your interest.',
                  'But official transcripts are required by the Tennessee Higher Education Commission.'
            );
    },
    async clickYes() {
      const data = await this.verifyStudentEmail({ email: this.email });
      console.log(data);
      if(data.exists){
        this.success = false;
        this.message = `It looks like you already have an account with us. Please call ${process.env.VUE_APP_PHLEBS_PHONE_NUMBER} for assistance`;
        return;
      }

      this.yes = true;
      this.no = false;
      this.$store.commit('formData/updateFirstTest', true);
      this.$emit('next');
    },
    clickNo: function (event) {
      this.yes = false;
      this.no = true;
      this.$store.commit('formData/updateFirstTest', false);
    }
  },
}
</script>

<style scoped>
</style>
