<template>
<div>

  <div v-if="title">
    <p class="font-bold text-lg">
      Thank you for your interest.
    </p>
    <p class="mt-6">
      But a <span class="font-bold">Certificate of Training or Proof of Training is required
      </span>due to the nature of this course.
    </p>
  </div>

  <!-- <p class="font-bold mt-8 text-lg ">Here are your options for successful enrollment:</p> -->
    <p class="mt-6">
      Click
      <a class="text-blue-900 underline" href="https://www.ivtrainingcenter.com/training-center/">
        Here
      </a>
      to enroll to a Phlebotomy Class to gain the required experience.
    </p>
    <p class="mt-6">
      Please call Student Serivices at {{ phlebsPhoneNumber }} if you have questions.
    </p>
</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props:{
    title:{
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(['phlebsPhoneNumber']),
  }
}
</script>