<template>

<div id="text-17" class="widget widget_text">
    <div class="textwidget">
        <div class="saSealContainer">
            <a href="https://www.shopperapproved.com/reviews/phlebotomyusa.com/" class="shopperlink"><img src="//www.shopperapproved.com/newseals/29114/white-mini-icon.gif" style="border: 0" alt="Customer Reviews" oncontextmenu="var d = new Date(); alert('Copying Prohibited by Law - This image and all included logos are copyrighted by Shopper Approved \251 '+d.getFullYear()+'.'); return false;"></a>
        </div>
    </div>
</div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
.saSealContainer {
    text-align: center !important;
}
</style>

// Script for the widget
<script type="text/javascript">
(function() { var js = window.document.createElement("script"); js.src = '//www.shopperapproved.com/seals/certificate.js'; js.type = "text/javascript"; document.getElementsByTagName("head")[0].appendChild(js); })();
</script>
