<template>
<div>
  <h4 class="text-xl font-semibold sm:text-3xl sm:font-normal leading-normal mt-2 text-red-900">
    Proof of High School Graduation or Equivalency
  </h4>

  <hr class="border-red-300 mt-5">


  <h3 class="text-xl font-normal leading-normal mt-8 text-red-900">
    Have you graduated High School, or completed an Equivalent General Education Course? Students will be required to submit a High School Diploma or Transcript, General Education Certificate, or College Transcripts or Diploma to be admitted
  </h3>

  <!-- Buttons -->
  <div class="flex justify-center my-10">
    <button @click="clickYes" :class="[ yes ? 'bg-red-300 font-semibold border-red-500': 'bg-red-100 hover:bg-red-200 border-red-200 hover:border-red-300' ]" class=" focus:outline-none border-2 px-8 py-4 mb-3 mr-6 rounded-lg ">
      <h5 :class="[ yes ? 'text-green-900': 'text-red-900' ]" class=" text-lg leading-tight truncate">YES</h5>
    </button>

    <!-- Mobile Button -->
    <button @click="showAlert" :class="[ no ? 'bg-red-300 font-semibold border-red-500': 'bg-red-100 hover:bg-red-200 border-red-200 hover:border-red-300' ]" class="lg:hidden focus:outline-none border-2 px-8 py-4 mb-3 mr-6 rounded-lg ">
      <h5 :class="[ no ? 'text-green-900': 'text-red-900' ]" class="text-lg leading-tight truncate">NO</h5>
    </button>
    <!-- End  Mobile Button -->

    <!-- Desktop Button -->
    <button @click="clickNo" :class="[ no ? 'bg-red-300 font-semibold border-red-500': 'bg-red-100 hover:bg-red-200 border-red-200 hover:border-red-300' ]" class="hidden lg:inline-block focus:outline-none border-2 px-8 py-4 mb-3 mr-6 rounded-lg ">
      <h5 :class="[ no ? 'text-green-900': 'text-red-900' ]" class="text-lg leading-tight truncate">NO</h5>
    </button>
    <!-- End Desktop Button -->

  </div>
  <!-- End Buttons -->

<side-message color="red" class="lg:hidden  p-2 -mt-8 pb-8" v-if="!diplomaProof">
  <nv-message :title="false"/>
</side-message>

</div>
</template>

<script>
import SideMessage from '@/components/Checkout/SideBar/Elements/SideMessage.vue'
import NVMessage from '@/components/Checkout/SideBar/Elements/Messages/NVMessage.vue'

import { mapState } from 'vuex'

export default {
  components: {
    SideMessage,
    'nv-message': NVMessage,
  },
  computed: {
    ...mapState('formData', [
      'diplomaProof'
    ]),
  },
  methods: {
    showAlert() {
      // Use sweetalert2
      this.clickNo();
      this.$swal('Thank you for your interest.',
                  'But a High School diploma or High School equivalency is necessary in order to take this class.'
          );
      window.scrollBy(0, 500);
    },
    clickYes: function (event) {
      this.yes = true;
      this.no = false;
      this.$store.commit('formData/setDiplomaProof', true);
      this.$emit('next');
    },
    clickNo: function (event) {
      this.yes = false;
      this.no = true;
      this.$store.commit('formData/setDiplomaProof', false);
    }
  },
  data: function (){
    return {
      yes: false,
      no: false
    }
  }
}
</script>

<style src='sweetalert2/dist/sweetalert2.min.css'/>
