<template>
  <tbody>
    <!-- <tr class="border-2">
      <td class="border-b px-4 py-2">Administrative Fee</td>
      <td class="border-b px-4 py-2">$ 100</td>
    </tr> -->
    <tr class="border-2">
      <td class="border-b px-4 py-2">Books</td>
      <td class="border-b px-4 py-2">$ 35</td>
    </tr>
    <tr class="border-2">
      <td class="border-b px-4 py-2">Supplies/Equipment Fee</td>
      <td class="border-b px-4 py-2">$ 180</td>
    </tr>
    <!--<tr class="border-2">
      <td class="border-b px-4 py-2">Class Tuition</td>
      <td class="border-b px-4 py-2">$ 995</td>
    </tr>-->
    <slot name="coupon-breakdown" v-bind:discountCode="discountCode"></slot>
    <tr class="border-2">
      <td class="border-b px-4 py-2 font-bold">Total For Tuition</td>
      <td class="border-b px-4 py-2 font-bold">$ {{ discountCode.isValid ? courseCost - ( discountCode.amount / 100 ) : courseCost }}</td>
    </tr>
    <!-- <tr>
      <td class="border-b px-4 py-2">Total For Tuition</td>
      <td class="border-b px-4 py-2">$ {{ courseCost }}</td>
    </tr> -->
    <tr>
      <td class="border-b px-4 py-2"><p class="px-4 py-2">&nbsp;</p></td>
      <td class="border-b px-4 py-2"><p class="px-4 py-2">&nbsp;</p></td>
    </tr>
    <!-- <tr v-if="!availableForPayment && courseCost >  0" class="border-2">
      <td class="border-b px-4 py-2">Deposit</td>
      <td class="border-b px-4 py-2">$ {{ depositAmount }}</td>
    </tr> -->
    <tr v-if="courseCost >  0" class="border-2 bg-blue-100 text-blue-800 font-semibold" >
      <td class="border-b px-4 py-2">Due by mid-class</td>
      <td class="border-b px-4 py-2">$ {{ totalPrice }}</td>
    </tr>
    <tr v-if="hasNationalExam" class="border-2">
      <td class="border-b px-4 py-2">
        <span :class="[ !withExamFee ? `line-through` : `` ]">National Exam Fee (optional)</span>
      </td>
      <td class="border-b px-4 py-2">
        <span :class="[ !withExamFee ? `line-through` : `` ]">$ {{ examFeeCost }}</span>
      </td>
    </tr>
    <tr>
      <td class="border-b px-4 py-2 text-center" colspan="2">
        <label class="font-bold">
          Total Payment
        </label>
      </td>
    </tr>
    <!-- <tr>
      <td class="border-b px-4 py-2">Total</td>
      <td class="border-b px-4 py-2">$ {{ totalPrice }}</td>
    </tr> -->
    <!-- <tr class="border-2">
      <td class="border-b px-4 py-2">{{ name }}</td>
      <td class="border-b px-4 py-2">$ {{ courseCost }}</td>
    </tr> -->
    <tr v-if="insuranceCost > 0">
      <td class="border-b px-4 py-2">Insurance</td>
      <td class="border-b px-4 py-2">$ {{ insuranceCost }}</td>
    </tr>
    <!-- <tr :class="[!payFull ? 'text-green-900': 'text-green-900 bg-green-100 font-semibold' ]">
      <td class="border-b px-4 py-2">Total</td>
      <td class="border-b px-4 py-2">$ {{ totalPrice }}</td>
    </tr> -->
    <tr v-if="paySelected === 'payFull' && availableForPayment" :class="['text-green-900 bg-green-100 font-semibold']">
      <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">
        Total for Tuition <span v-if="withExamFee">& Exam Fee</span>
      </td>
      <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">$ {{ totalPayment }}</td>
    </tr>

    <tr v-if="paySelected === 'payNotFull' && availableForPayment" :class="[ 'text-green-900 bg-green-100 font-semibold' ]">
      <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">Registration</td>
      <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">$ {{ depositAmount }}</td>
    </tr>
    <tr v-if="paySelected === 'payCustom' && availableForPayment" :class="['text-green-900 bg-green-100 font-semibold']">
      <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">Your Payment</td>
      <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">$ {{ payCustomAmount }}</td>
    </tr>

    <tr :class="[ 'text-green-900 bg-green-100 font-semibold' ]">
      <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2" v-if="availableForPayment">
        + Application
      </td>
      <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2" v-else>
        Application
      </td>
      <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">$ {{ applicationFee }}</td>
    </tr>



    <!-- <tr v-if="!payFull && !payCustom" class="bg-red-100 text-red-900 font-semibold">
      <td class="border-t-2 border-b-2 border-l-2 border-red-400 px-4 py-2">Remaining Balance</td>
      <td class="border-t-2 border-b-2 border-r-2 border-red-400 px-4 py-2">$ {{ totalRemainingBalance }}</td>
    </tr>

    <tr v-if="payCustom" class="bg-red-100 text-red-900 font-semibold">
      <td class="border-t-2 border-b-2 border-l-2 border-red-400 px-4 py-2">Remaining Balance</td>
      <td class="border-t-2 border-b-2 border-r-2 border-red-400 px-4 py-2">$ {{ totalRemainingBalance }}</td>
    </tr> -->

    <tr :class="[ 'text-green-900 bg-green-100 font-semibold' ]">
      <td
        class="border-t-2 border-l-2 border-green-400 px-4 py-2"
        :class="[paySelected === 'payFull' ? 'border-b-2' : '']"
      >
        Total Payment Today
      </td>
      <td
        class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2"
        v-if="paySelected === 'payFull'"
      >
        $ {{ totalPayment + applicationFee }}
      </td>
      <td
        class="border-t-2 border-r-2 border-green-400 px-4 py-2"
        v-else-if="paySelected === 'payNotFull'"
      >
        $ {{ depositAmount + applicationFee }}
      </td>
      <td
        class="border-t-2 border-r-2 border-green-400 px-4 py-2"
        v-else-if="paySelected === 'payCustom'"
      >
        $ {{ payCustomAmount + applicationFee }}
      </td>
      <td
        class="border-t-2 border-r-2 border-green-400 px-4 py-2"
        v-else
      >
        $ {{ applicationFee }}
      </td>
    </tr>
    <tr :class="[ 'text-red-900 bg-red-100 font-semibold' ]" v-if="paySelected !== 'payFull'">
      <td class="border-t-2 border-b-2 border-l-2 border-red-400 px-4 py-2">
        Remaining Balance
      </td>
      <td
        class="border-t-2 border-b-2 border-r-2 border-red-400 px-4 py-2"
      >
        $ {{ totalRemainingBalance  }}
      </td>
    </tr>

  </tbody>
</template>

<script>
  export default {
    props:[
    'insuranceCost',
    'payFull',
    'payCustom',
    'payCustomAmount',
    'courseCost',
    'name',
    'totalPrice',
    'depositAmount',
    'remainingBalance',
    'hasNationalExam',
    'examFeeCost',
    'availableForPayment',
    'with-exam-fee',
    'application-fee',
    'pay-selected',
    'discountCode'
    ],
    computed: {
      totalPayment(){
        if(this.withExamFee){
          return this.totalPrice + this.examFeeCost + (!this.availableForPayment ? this.applicationFee : 0)
        }
        return this.totalPrice + (!this.availableForPayment ? this.applicationFee : 0);
      },
      totalRemainingBalance(){
        if(this.withExamFee){
          return this.remainingBalance + this.examFeeCost
        }
        return this.remainingBalance;
      }
    }
  }
</script>