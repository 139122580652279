<template>
  <div class="flex">
    <!-- Left side of checkout -->
    <div class="w-full lg:w-3/5 bg-white">
      <error-message></error-message>

      <div v-if="valid == 'loading'">
        <h2 class="p-10 mb-40 text-2xl text-red-900 text-center">
          <!-- We are Loading Your Checkout -->
        </h2>
      </div>
      <div v-else-if="valid == 'finished'">
        <div v-if="!stateFeeFound" class="p-10 mb-32 text-2xl text-red-900">
          <h2 class="mb-6">
            We couldn't find fees associated to this course.
          </h2>
          <h3 class="text-xl">
            Please try another one
            <a
              href="https://www.phlebotomyusa.com/phlebotomy-school-locations/"
              class="hover:text-blue-700 text-blue-500 underline font-bold"
              >HERE</a
            >. Or call {{ phlebsPhoneNumber }}
          </h3>
        </div>
        <div
          v-else-if="courseHasStarted"
          class="p-10 mb-32 text-2xl text-red-900"
        >
          <h2 class="mb-6">
            Sorry, this class has already started
          </h2>
          <h3 class="text-xl" v-if="landing_page">
            Please try another one
            <a
              :href="landing_page"
              class="hover:text-blue-700 text-blue-500 underline font-bold"
              >HERE</a
            >. Or call {{ phlebsPhoneNumber }}
          </h3>
          <h3 class="text-xl" v-else>
            Please try another one
            <a
              href="https://www.phlebotomyusa.com/phlebotomy-school-locations/"
              class="hover:text-blue-700 text-blue-500 underline font-bold"
              >HERE</a
            >. Or call {{ phlebsPhoneNumber }}
          </h3>
        </div>
        <div v-else>
          <progress-bar
            :step="currentForm"
            :number-steps="numForms - 1"
            class="mx-8 my-6"
            v-if="courseTypeId !== 11"
          ></progress-bar>
          <progress-bar-ecg
            :step="currentForm"
            :number-steps="numForms - 1"
            class="mx-8 my-6"
            v-else
          ></progress-bar-ecg>

          <div class="mx-8 mt-8 ">
            <!-- <form-english v-if="currentForm == numForms - 3 && state == 'CA'" v-on:next="next"></form-english> -->
            <!-- <form-diploma-proof v-if="currentForm == numForms - 3 && state == 'NV'" v-on:next="next"></form-diploma-proof> -->

            <!-- <form-transcripts v-if="currentForm == numForms - 4 && state == 'TN'" v-on:next="next"></form-transcripts> -->
            <!-- <form-tx-applicants v-if="currentForm == numForms - 4 && state == 'TX'" v-on:next="next"/> -->
            <form-init-iv
              v-if="currentForm == numForms - 3 && courseType == 'IV'"
              v-on:next="next"
            />
            <div
              class="hidden lg:block h-56"
              v-if="
                currentForm == numForms - 3 &&
                  state != 'KY' &&
                  currentForm == numForms - 3 &&
                  state != 'TN'
              "
            ></div>

            <form-basic
              v-if="
                currentForm == numForms - 2 &&
                  state != 'KY' &&
                  currentForm == numForms - 2 &&
                  state != 'TN'
              "
              :has-intro-question="this.hasIntroQuestion"
              v-on:next="next"
              v-on:back="back"
            >
            </form-basic>

            <!-- Steps Order for TN state -->
            <form-basic
              v-if="currentForm == numForms - 5 && state == 'TN'"
              :has-intro-question="this.hasIntroQuestion"
              v-on:next="next"
              v-on:back="back"
            ></form-basic>
            <form-address
              v-if="currentForm == numForms - 4 && state == 'TN'"
              v-on:next="next"
              v-on:back="back"
            >
            </form-address>
            <form-tn-checklist
              v-if="currentForm == numForms - 3 && state == 'TN'"
              v-on:next="next"
              v-on:back="back"
            >
            </form-tn-checklist>
            <form-tn-disclosure
              v-if="currentForm == numForms - 2 && state == 'TN'"
              v-on:next="next"
              v-on:back="back"
            >
            </form-tn-disclosure>
            <!-- Steps for TN state -->
            <!-- Steps Order for KY state -->
            <form-basic
              v-if="currentForm == numForms - 4 && state == 'KY'"
              :has-intro-question="this.hasIntroQuestion"
              v-on:next="next"
              v-on:back="back"
            ></form-basic>
            <form-address
              v-if="currentForm == numForms - 3 && state == 'KY'"
              v-on:next="next"
              v-on:back="back"
            >
            </form-address>
            <form-pre-enrollment
              v-if="currentForm == numForms - 2 && state == 'KY'"
              v-on:next="next"
              v-on:back="back"
            >
            </form-pre-enrollment>
            <!-- Steps for KY state -->

            <!-- <form-address
            v-if="currentForm == numForms - 2 && (state !='KY' && state != 'TN')"
            v-on:next="next" v-on:back="back"
          ></form-address> -->

            <!-- <div> -->
            <form-payment
              v-if="currentForm == numForms - 1"
              :loading="loading"
              v-on:next="submit"
              v-on:back="back"
            ></form-payment>
            <!-- </div> -->

            <success-message
              v-if="currentForm == numForms && !applyForApplicants"
            ></success-message>
            <success-message-state-reqs
              v-if="currentForm == numForms && applyForApplicants"
            ></success-message-state-reqs>
          </div>
        </div>
      </div>
      <div v-else-if="valid == 'full'">
        <div class="p-10 mb-32 text-2xl text-red-900">
          <h2 class="mb-6">
            Sorry, this class is sold out
          </h2>
          <h3 class="text-xl" v-if="landing_page">
            Please try another one
            <a
              :href="landing_page"
              class="hover:text-blue-700 text-blue-500 underline font-bold"
              >HERE</a
            >. Or call {{ phlebsPhoneNumber }}
          </h3>
        </div>
      </div>
      <div v-else-if="valid == false" class="p-10 mb-32 text-2xl text-red-900">
        <h2 class="mb-6">
          We couldn't find that course.
        </h2>
        <h3 class="text-xl">
          Please try another one
          <a
            href="https://www.phlebotomyusa.com/phlebotomy-school-locations/"
            class="hover:text-blue-700 text-blue-500 underline font-bold"
            >HERE</a
          >. Or call {{ phlebsPhoneNumber }}
        </h3>
      </div>
      <!-- <div v-else-if="valid == false" class="p-10 mb-32 text-2xl text-red-900">
        <h2 class="mb-6">
          We couldn't find that course.
        </h2>
        <h3 class="text-xl">
          Please try another one
          <a
            href="https://www.phlebotomyusa.com/phlebotomy-school-locations/"
            class="hover:text-blue-700 text-blue-500 underline font-bold"
            >HERE</a
          >. Or call {{ phlebsPhoneNumber }}
        </h3>
      </div> -->
    </div>
    <!-- End left side of checkout -->

    <!-- Right side of checkout -->
    <div class="hidden lg:block w-2/5">
      <side-bar :current-form="currentForm" :num-forms="numForms"></side-bar>
    </div>
    <!-- End right side of checkout -->
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";

import { setInteractionMode } from "vee-validate/dist/vee-validate.full.esm";

setInteractionMode("eager");

import ProgressBar from "./Elements/ProgressBar.vue";
import ProgressBarECG from "./Elements/ProgressBarECG.vue";
import ErrorMessage from "./Elements/ErrorMessage.vue";

import FormEnglish from "./Forms/FormEnglish.vue";
import FormDiplomaProof from "./Forms/FormDiplomaProof.vue";
import FormTranscripts from "./Forms/FormTranscripts.vue";
import FormTxApplicants from "./Forms/FormTxApplicants";
import FormInitIV from "./Forms/FormInitIV";
import FormBasic from "./Forms/FormBasic.vue";
import FormAddress from "./Forms/FormAddress.vue";
import FormPayment from "./Forms/FormPayment.vue";
import FormPreEnrollment from "./Forms/FormPreEnrollment";
import FormTNChecklist from "./Forms/FormTNChecklist";
import FormTNDisclosure from "./Forms/FormTNDisclosure.vue";
import SuccessMessage from "./Forms/SuccessMessage.vue";
import SuccessMessageStateReqs from "./Forms/SuccessMessageStateReqs.vue";

import SideBar from "./SideBar/SideBar.vue";

export default {
  components: {
    "progress-bar": ProgressBar,
    "progress-bar-ecg": ProgressBarECG,
    "error-message": ErrorMessage,
    "form-english": FormEnglish,
    FormDiplomaProof,
    "form-transcripts": FormTranscripts,
    "form-init-iv": FormInitIV,
    "form-basic": FormBasic,
    "form-address": FormAddress,
    "form-payment": FormPayment,
    "success-message": SuccessMessage,
    "success-message-state-reqs": SuccessMessageStateReqs,
    FormPreEnrollment,
    "form-tn-checklist": FormTNChecklist,
    "form-tn-disclosure": FormTNDisclosure,
    FormTxApplicants,
    "side-bar": SideBar,
  },
  methods: {
    next: function(event) {
      if (!this.isNextPixelTriggered) {
        if (this.courseType == "National") {
          if (typeof fbq === "function") {
            // Trigger Facebook Pixel code
            console.log("Next button triggered.");
            fbq("track", "InitiateCheckout");
          } else {
            console.log(
              "fbq is not defined. Facebook Pixel code not triggered."
            );
          }
        }
        this.$store.commit("checkoutData/fireNextPixel");
      }
      if (this.isFormBasicCurrent) {
        this.$store.dispatch("formData/updateLeadOnNext");
      }
      if (this.currentForm <= 4) {
        this.$store.commit("checkoutData/incrementCurrentForm");
        //this.$emit('changeForm', this.currentForm);
        // Scroll to the top
        window.scroll(0, 0);
      }
    },
    back: function(event) {
      if (this.currentForm > 1) {
        this.$store.commit("checkoutData/decrementCurrentForm");
        //this.$emit('changeForm', this.currentForm);
      }
    },
    submit: function(event) {
      this.$store.commit("checkoutData/updateLoading", true);
      this.$store.dispatch("formData/submitData");
    },
  },
  computed: {
    ...mapState(["phlebsPhoneNumber"]),
    ...mapState("checkoutData", [
      "loading",
      "currentForm",
      "numForms",
      "isNextPixelTriggered",
    ]),
    ...mapState("courseInformation", [
      "valid",
      "stateFeeFound",
      "id",
      "name",
      "state",
      "termsOfServiceLink",
      "courseCost",
      "examFeeCost",
      "insuranceCost",
      "depositAmount",
      "courseType",
      "courseTypeId",
      "courseStartDate",
      "landing_page",
      "applyForApplicants",
    ]),
    ...mapGetters("formData", ["formatedNumber", "formatedDob"]),
    ...mapGetters("courseInformation", ["totalPrice", "hasIntroQuestion"]),
    courseHasStarted: function() {
      const courseDate = this.$moment(this.courseStartDate).format(
        "YYYY-MM-DD"
      );
      const today = this.$moment().format("YYYY-MM-DD");
      console.log(this.courseStartDate);
      if (today > courseDate) return true;
      return false;
    },
    isFormBasicCurrent: function() {
      if (this.state != "KY" && this.state != "TN") {
        return this.currentForm == this.numForms - 2;
      }
      if (this.state == "TN") {
        return this.currentForm == this.numForms - 5;
      }
      if (this.state == "KY") {
        return this.currentForm == this.numForms - 4;
      }
      return false;
    },
  },
  mounted: function() {
    // Get Curse Information From Server
    //console.log(this.$route.query);
    //const { c, type } = this.$route.query;
    this.$store.dispatch("courseInformation/loadData", {
      courseId: this.$route.query.c,
      courseType: this.$route.query.type,
    });

    const urlQuery = this.$route.query;
    if (urlQuery.first_name) {
      this.$store.commit("formData/updateName", urlQuery.first_name);
    }
    if (urlQuery.last_name) {
      this.$store.commit("formData/updateLastName", urlQuery.last_name);
    }
    if (urlQuery.email) {
      this.$store.commit("formData/updateEmail", urlQuery.email);
    }
    if (urlQuery.phone) {
      this.$store.commit("formData/updateNumber", urlQuery.phone);
    }
  },
};
</script>
