<template>
  <div class="w-9/12 mb-2">
    <validation-provider :name="name" v-slot="{ errors }">
      <label class="text-red-900 text-md font-bold ml-2 mb-2">
        <input
          class="mr-2"
          type="checkbox"
          v-model="model"
          style="display: none;"
        >
        <span class="text-xs md:text-sm">
          {{ text }}
        </span>
      </label>
      <p class="mt-1 ml-1 text-red-500 text-xs md:text-sm font-semibold italic">{{ errors[0]}}</p>
    </validation-provider>
  </div>
</template>

<script>
  import { ValidationProvider} from 'vee-validate/dist/vee-validate.full.esm';
  export default {
    components: {
      'validation-provider': ValidationProvider,
    },
    props:[ 'model', 'text', 'name'],
  }
</script>

<style lang="scss" scoped>

</style>