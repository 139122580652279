import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import CheckoutData from './modules/CheckoutData';
import CourseInformation from './modules/CourseInformation';
import ErrorMessage from './modules/ErrorMessage';
import FormData from './modules/FormData';
import ResponseData from './modules/ResponseData';
import Transcript from './modules/Transcript';
import TxApplicant from './modules/TxApplicant';

export default new Vuex.Store({
  state: () => ({
    phlebsPhoneNumber: process.env.VUE_APP_PHLEBS_PHONE_NUMBER,
  }),
  getters: {
    formCssClasses: (state, getters, rootState) => (attribute) => {
      const { courseTypeId } = rootState.courseInformation;
      //console.log(attribute);
      switch (attribute) {
        case 'label':
          return {
            'text-green-ecg': courseTypeId === 11,
            'text-gray-800': courseTypeId !== 11,
            // 'text-red-900': courseTypeId !== 11,
          };
        case 'paragraph':
          return {
            'text-green-ecg': courseTypeId === 11,
            // 'text-red-900': courseTypeId !== 11,
            'text-gray-800': courseTypeId !== 11,
          };
        case 'upload_button':
          console.log(attribute);
          return {
            'text-green-ecg bg-transparent border-2 border-green-ecg rounded-full': courseTypeId === 11,
            'text-white bg-red-800 rounded': courseTypeId !== 11,
          };
        case 'input':
          return {
            'placeholder-green-ecg text-green-ecg': courseTypeId === 11,
            // 'placeholder-red-300 text-red-900': courseTypeId !== 11,
            'placeholder-gray-500 text-gray-900': courseTypeId !== 11,
            'border border-gray-800' : true,
            'shadow-md':true
          };
        case 'radio_button':
          return {
            'focus:outline-green-100 text-green-400': courseTypeId === 11,
            'focus:outline-red-100 text-red-400': courseTypeId !== 11 && courseTypeId !== 4,
            'focus:outline-blue-100 text-blue-700': courseTypeId === 4,
            'border-2 border-gray-500' :true
          };
        case 'checkbox':
          return {
            'focus:outline-green-100 text-green-400': courseTypeId === 11,
            'focus:outline-red-100 text-red-400': courseTypeId !== 11 && courseTypeId !== 4,
            'focus:outline-blue-100 text-blue-400': courseTypeId === 4
          };
        case 'hr':
          return {
            'border-green-hr-ecg': courseTypeId === 11,
            'border-red-300': courseTypeId !== 11,
          };
        default:
          return {};
      }
    }
    // labelFormCssClasses: (state, getters, rootState) => {
    //   rootState['courseInformation/courseTypeId']
    // },
    // placeholderFormCssClasses: (state, getters, rootState) => {

    // }
  },
  modules: {
    checkoutData:  CheckoutData,
    courseInformation:  CourseInformation,
    errorMessage:  ErrorMessage,
    formData:  FormData,
    responseData:  ResponseData,
    'transcript': Transcript,
    'txApplicant': TxApplicant,
  }
})
