var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex mt-3"},[_c('div',{staticClass:"w-full mb-3 pt-0"},[_c('label',{staticClass:"hidden sm:block text-md font-bold ml-2 mb-2",class:{
        ..._vm.formCssClasses('label'),
      }},[_vm._v(" Password ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"sm:hidden px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none outline-red-100 focus:outline-red-200 w-full",class:{
        'border-2 border-red-500': _vm.errors.password,
        ..._vm.formCssClasses('input'),
      },attrs:{"type":"text","placeholder":"Type your password"},domProps:{"value":(_vm.password)},on:{"blur":_vm.onBlurPassword,"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"hidden sm:block px-3 py-4 relative bg-white bg-white rounded text-base shadow outline-none focus:outline-red-200 w-full",class:{
        'border-2 border-red-500': _vm.errors.password,
        ..._vm.formCssClasses('input'),
      },attrs:{"type":"text","placeholder":"Type your password"},domProps:{"value":(_vm.password)},on:{"blur":_vm.onBlurPassword,"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('p',{staticClass:"mt-1 ml-1 text-red-500 text-sm font-semibold italic"},[_vm._v(" "+_vm._s(_vm.errors.password)+" ")]),_c('div',{staticClass:"mt-10 mb-0"},[_c('button',{staticClass:"w-full bg-green-500 hover:bg-green-600 focus:outline-none text-white font-bold px-3 py-4 rounded",attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.onCreateAccount}},[_c('div',{staticClass:"flex justify-center"},[_c('moon-loader',{attrs:{"loading":_vm.loading,"color":"#FFF5F5","size":"25px"}}),(!_vm.loading)?_c('p',[_vm._v("Create account")]):_vm._e()],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }