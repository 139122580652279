var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-red-800"},[(_vm.envelopeId)?_c('h3',{staticClass:"text-2xl sm:text-3xl font-normal leading-normal mt-2",class:{
      ..._vm.formCssClasses('paragraph'),
    }},[_vm._v(" You're almost done! ")]):_vm._e(),(!_vm.envelopeId)?_c('h3',{staticClass:"text-2xl sm:text-3xl font-normal leading-normal mt-2",class:{
      ..._vm.formCssClasses('paragraph'),
    }},[_vm._v(" You're almost done! ")]):_vm._e(),(!_vm.envelopeId)?_c('h3',{staticClass:"text-xl sm:text-2xl font-normal leading-normal mt-2",class:{
      ..._vm.formCssClasses('paragraph'),
    }},[_vm._v(" Your registration has been received. ")]):_vm._e(),_c('div',{staticClass:"mb-12"},[(false)?_c('p',{staticClass:"mt-8 font-bold text-lg",class:{
        ..._vm.formCssClasses('paragraph'),
      }},[_vm._v(" Please click "),_c('a',{staticClass:"hover:text-blue-700 text-blue-500 underline font-bold",attrs:{"href":_vm.link,"target":"_blank"}},[_vm._v(" THIS LINK ")]),_vm._v(" to confirm your Registration AND to sign your paperwork. NOTE: Your seat will not be saved until your paperwork has been completed. ")]):_vm._e(),_c('p',{staticClass:"mt-2 font-bold text-md",class:{
        ..._vm.formCssClasses('paragraph'),
      }},[_vm._v(" We’ve got your application! To finalize your registration and add you to the class roster, we need two things from you. ")]),_c('p',{staticClass:"mt-1 font-bold text-md",class:{
        ..._vm.formCssClasses('paragraph'),
      }},[_vm._v(" First, create a Password and Log into your Student Portal. ")]),_c('p',{staticClass:"mt-1 font-bold text-md",class:{
        ..._vm.formCssClasses('paragraph'),
      }},[_vm._v(" Here, you will complete your Student Profile and Enrollment Paperwork via Docusign. ")]),_c('p',{staticClass:"mt-1 font-bold text-md",class:{
        ..._vm.formCssClasses('paragraph'),
      }},[_vm._v(" Second, upload proof of graduation. ")]),_c('p',{staticClass:"mt-1 font-bold text-md",class:{
        ..._vm.formCssClasses('paragraph'),
      }},[_vm._v(" We can accept college or high school transcripts, or transcripts of equivalency. ")]),_c('p',{staticClass:"mt-1 font-bold text-md",class:{
        ..._vm.formCssClasses('paragraph'),
      }},[_vm._v(" When these things have been completed, congrats! You are officially on our roster and ready to start class. ")]),_c('hr',{staticClass:"border-red-300 mt-5"}),(!_vm.isAccountCreated)?_c('form-create-account'):_vm._e(),(_vm.envelopeId && !_vm.isAccountCreated)?_c('p',{staticClass:"font-bold text-lg",class:{
        ..._vm.formCssClasses('paragraph'),
      }},[_vm._v(" Please enter a password to create your Student Account ")]):_vm._e(),(!_vm.envelopeId && !_vm.isAccountCreated)?_c('p',{staticClass:"mt-0 font-bold text-lg",class:{
        ..._vm.formCssClasses('paragraph'),
      }},[_vm._v(" Please enter a password to create your Student Account ")]):_vm._e(),(_vm.isAccountCreated)?_c('created-account-message'):_vm._e(),(false)?_c('div',[_c('p',{staticClass:"mt-8",class:{
          ..._vm.formCssClasses('paragraph'),
        }},[(_vm.envelopeId)?_c('span',[_vm._v(" You will also be able to ")]):_vm._e(),(!_vm.envelopeId)?_c('span',[_vm._v(" This is where you will be able to ")]):_vm._e(),_c('span',{staticClass:"font-bold"},[_vm._v("manage your classes & make payments.")])]),_c('p',{staticClass:"mt-8 text-red-900",class:{
          ..._vm.formCssClasses('paragraph'),
        }},[_vm._v(" Please be sure to "),_c('span',{staticClass:"font-bold"},[_vm._v(" check your student information ")]),_vm._v(" & make sure it is all entered correctly. Your name on your "),_c('span',{staticClass:"font-bold"},[_vm._v(" certificate will appear EXACTLY as it is entered ")]),_vm._v(" here. ")]),_c('div',{staticClass:"flex justify-center pt-6"},[_c('div',{},[_c('div',{staticClass:"w-full p-6 bg-red-200 text-red-900 border-red-400 border rounded-lg"},[_c('div',{staticClass:"flex"},[_vm._m(0),_c('div',[_c('p',[_vm._v(_vm._s(_vm.getSuccessTitleTrainingCourse))]),_c('p',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.dateStart,"L"))+" - "+_vm._s(_vm._f("moment")(_vm.dateEnd,"L"))+" ")]),_c('p',[_vm._v(_vm._s(_vm.city))]),_c('p',[_vm._v(_vm._s(_vm.formattedTimes))])])]),_c('div',[_c('p',{staticClass:"mt-5 -mb-4 text-sm"},[_vm._v(" If this information is wrong call "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.phlebsPhoneNumber))])])])])])]),_c('p',{staticClass:"mt-8 text-sm",class:{
          ..._vm.formCssClasses('paragraph'),
        }},[_vm._v(" Our staff will be in touch with you by Email and Text Message between now and when class starts. ")]),_c('p',{staticClass:"mt-12 text-lg text-red-900",class:{
          ..._vm.formCssClasses('paragraph'),
        }},[_vm._v(" Thank you. We look forward to seeing you soon! ")]),_c('div',[_c('success-message-sidebar',{staticClass:"mt-10 lg:hidden",attrs:{"color-text":"text-red-900","color-text-bold":"text-red-900"}})],1)]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-6"},[_c('p',{staticClass:"font-bold"},[_vm._v("Class")]),_c('p',{staticClass:"font-bold"},[_vm._v("Dates")]),_c('p',{staticClass:"font-bold"},[_vm._v("Location")]),_c('p',{staticClass:"font-bold"},[_vm._v("Time")])])
}]

export { render, staticRenderFns }