import { render, staticRenderFns } from "./VFooterIV.vue?vue&type=template&id=6526fe21&scoped=true"
import script from "./VFooterIV.vue?vue&type=script&lang=js"
export * from "./VFooterIV.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6526fe21",
  null
  
)

export default component.exports