<template>
<div>
  <footer>
      <div
        class="w-full pin-b text-center bg-white p-4"
        :style="style.div"
      >
        <h1 :style="style.h1">{{ phlebsPhoneNumber }}</h1>
        <h4 :style="style.h4">© I.V. Training Center - All Rights Reserved</h4>
    </div>
  </footer>
</div>
</template>


<script>
import { mapState } from 'vuex';

export default {
  data: ()=>({
    style: {
      div: {
        'box-shadow': '0px -6px 5px 0px rgba(0,0,0,0.3)',
      },
      h1: {
        'color': '#0A457A',
        'font-size': '2em',
        'font-weight': '500',
        'line-height': '1.2',
      },
      h4: {
        'font-size': '1em',
        'color': '#404040',
        'font-weight': '500',
        'line-height': '1.2',
      }
    }
  }),
  computed: {
    ...mapState(['phlebsPhoneNumber']),
  }

}
</script>

<style scoped>
</style>
