<template>
  <div class="relative pt-1 ">
    <div class="flex mb-2 items-center justify-center">
      <div>
        <span
          :class="[ (step == (numberSteps + 1)) ? 'text-green-800 bg-green-200': getStepColor ]"
          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full"
        >
          <!-- {{ (step > numberSteps) ? "Complete" : "Step " + step + "/" + numberSteps }} -->
        <span v-if="(step < (numberSteps + 1)) || ( envelopeId && (step == (numberSteps + 1)) )">
          {{ "Step " + step + "/" + (numberSteps + 1) }}
        </span>
        <span v-if="!envelopeId && (step == (numberSteps + 1))">
          {{ "Complete" }}
        </span>
        </span>
      </div>
    </div>
    <div class="overflow-hidden h-2 mb-4 text-xs flex rounded" :class="getBGProgressBarColor">
      <div ref="progressbar-bar" :class="[ (step > numberSteps) ? 'bg-green-400': getProgressBarColor ]" v-bind:style="styleBar" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center transition-all duration-300"></div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
  props: {
    step: Number,
    numberSteps: Number
  },
  computed: {
    ...mapState('courseInformation', ['courseTypeId']),
    ...mapState('responseData', ['envelopeId']),
    styleBar: function() {
      return { width: this.barPercentage + "%"};
    },
    barPercentage: function() {
      return ( (this.step) / (this.numberSteps + 1) ) * 100;
    },
    getStepColor(){
      return this.courseTypeId == 4 ? 'text-blue-800 bg-blue-200' : 'text-red-800 bg-red-200';
    },
    getBGProgressBarColor(){
      return this.courseTypeId == 4 ? 'bg-blue-200' : 'bg-red-200';
    },
    getProgressBarColor(){
      return this.courseTypeId == 4 ? 'bg-blue-400' : 'bg-red-400';
    }
  }
}
</script>

<style lang="css" scoped>
</style>
