<template>
<div>
  <nav class="flex items-center justify-between flex-wrap bg-white p-6">
      <div class="flex items-center flex-shrink-0 text-white mr-6">
          <img class="h-10 sm:h-12" src="https://ecgtrainingspecialists.com/wp-content/themes/phlebotomy_usa/images/logo.jpg" alt="Phlebotomy USA">
      </div>
      <!-- <div class="absolute inset-y-0 left-0 flex items-center sm:hidden"> -->
      <div class="lg:hidden block flex w-auto">

          <mobile-menu-button @click.native="toggleMenu" :active="hamburgerButton" ></mobile-menu-button>
      </div>
      <!-- Desktop Menu -->
      <div class="hidden w-full lg:block flex-grow lg:flex lg:items-center lg:w-auto">
          <div class="text-sm lg:flex-grow">
              <a href="https://ecgtrainingspecialists.com/ecg-center-locations/" class="block mt-4 lg:inline-block lg:mt-0 text-menu-green-ecg lg:ml-6 mr-8">
                  Locations
              </a>
              <a href="https://ecgtrainingspecialists.com/about-the-course/" class="block mt-4 lg:inline-block lg:mt-0 text-menu-green-ecg mr-8">
                  About the Course
              </a>
              <a href="https://ecgtrainingspecialists.com/faq/" class="block mt-4 lg:inline-block lg:mt-0 text-menu-green-ecg mr-8">
                  FAQs
              </a>
              <a href="https://ecgtrainingspecialists.com/contact-ecg-training-centers/" class="block mt-4 lg:inline-block lg:mt-0 text-menu-green-ecg mr-8">
                  Contact
              </a>
              <a href="https://ecgtrainingspecialists.com/phlebotomy-iv/" class="block mt-4 lg:inline-block lg:mt-0 text-menu-green-ecg mr-10">
              Phlebotomy/IV Training
              </a>
              <a class="hidden lg:block lg:mt-4 lg:inline-block lg:mt-0 text-xl text-menu-green-ecg">
              {{ phlebsPhoneNumber }}
              </a>
          </div>
      </div>
      <!-- End Desktop Menu -->
      <!-- Mobile Menu -->
      <div v-if="hamburgerButton" class="w-full lg:hidden">
          <div class="text-md mt-4">
              <a href="https://www.phlebotomyusa.com/phlebotomy-school-locations/" class="hover:bg-gray-200 rounded-lg block px-4 py-3 text-gray-700 hover:text-gray-800 ">
                  LOCATIONS
              </a>
              <a href="https://www.phlebotomyusa.com/veinviewer/" class="hover:bg-gray-200 rounded-lg block px-4 py-3 text-gray-700 hover:text-gray-800 ">
                  VEINVIEWER
              </a>
              <a href="https://www.phlebotomyusa.com/phlebotomy-training-specialists/" class="hover:bg-gray-200 rounded-lg block px-4 py-3 text-gray-700 hover:text-gray-800 ">
                  ABOUT US
              </a>
              <a href="https://www.phlebotomyusa.com/contact-phlebotomy-training-specialists/" class="hover:bg-gray-200 rounded-lg block px-4 py-3 text-gray-700 hover:text-gray-800 ">
                  CONTACT US
              </a>
              <a href="https://www.phlebotomyusa.com/blog/" class="hover:bg-gray-200 rounded-lg block px-4 py-3 text-gray-700 hover:text-gray-800 ">
                  BLOG
              </a>
          </div>
      </div>
      <!-- End Mobile Menu -->
  </nav>
</div>
</template>

<script>
import { mapState } from 'vuex';
import DesktopMenu from '@/components/Layout/NavBar/DesktopMenu.vue';
import MobileMenu from '@/components/Layout/NavBar/MobileMenu.vue';
import MobileMenuButton from '@/components/Layout/NavBar/MobileMenuButton.vue';

export default {
  components: {
    'mobile-menu-button': MobileMenuButton
  },
  methods: {
    toggleMenu: function () {
      this.hamburgerButton = !this.hamburgerButton;
    }
  },
  data: function() {
    return {
      hamburgerButton: false
    }
  },
  computed: {
      ...mapState(['phlebsPhoneNumber']),
  }

}
</script>

<style>

</style>
