<template>
<div>
  <p class="font-bold text-lg">
    Thank you for your interest.
  </p>
  <p class="mt-8 text-lg">
    But official transcripts are <span class="font-bold">required</span> by the <span class="font-bold">Tennessee Higher Education Commission</span>.
  </p>

  <p class="mt-6">
    Please call Student Services at <span class="font-bold">{{ phlebsPhoneNumber }}</span> if you have questions
  </p>
</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState(['phlebsPhoneNumber']),
  }
}
</script>
