import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Validations from './validations';

Vue.component('validation-provider', Validations);
//Vue.use(VeeValidate);
import VueSweetalert2 from 'vue-sweetalert2'
import moment from 'moment';


Vue.config.productionTip = false

// Third Party Libraries
window.axios = require('axios');

Vue.use(require('vue-moment'));
Vue.use(VueSweetalert2);

Vue.filter('moment', (value, format) => {
  return moment(value).format(format);
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
