import axios from 'axios';

export default {
  namespaced: true,
  state: {
    isProcessing: false,
    isTranscriptReceived: false,
  },
  mutations: {
    setIsProcessing: (state, payload) => {
      state.isProcessing = payload;
    },
    setIsTranscriptReceived: (state, payload) => {
      state.isTranscriptReceived = payload;
    }
  },
  actions: {
    async verifyStudentEmail({commit}, { email }){
      try {
        commit('setIsProcessing', true);
        const { data } = await axios.get(`${process.env.VUE_APP_API_URL}api/student/${email}/verify`);

        commit('setIsProcessing', false);
        return data;
      } catch (error) {
        commit('setIsProcessing', false);
        commit('errorMessage/updateStatus', true, {root:true} );
        commit('errorMessage/updateType', 'Server', {root:true} );
        commit('errorMessage/updateMessage', 'Please try again.', {root:true} );
        console.log(error);
        return null;
      }
    },
    async verifyStudent({commit}, {post}){
      try {
        commit('setIsProcessing', true);
        const { data } = await axios.post(`${process.env.VUE_APP_API_URL}api/tn_pre_registration/check_student`, post);
        if(data.transcript_received){
          commit('setIsTranscriptReceived', data.transcript_received);
          commit('formData/updateEmail', post.email, {root:true})
        }
        commit('setIsProcessing', false);
        return data;
      } catch (error) {
        commit('setIsProcessing', false);
        commit('errorMessage/updateStatus', true, {root:true} );
        commit('errorMessage/updateType', 'Server', {root:true} );
        commit('errorMessage/updateMessage', 'Please try again.', {root:true} );
        console.log(error);
        return null;
      }
    }
  }
}