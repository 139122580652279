<template>
  <div
    class="w-2/12 mb-2 text-center"
    :class="{'self-center': !sign, 'self-center': sign}"
    style="border-bottom: 1px solid black;">
    <div
      class="w-auto h-full bg-yellow-300 cursor-pointer"
      v-if="!model"
      @click="$emit('on-sign')" >
        <span class="text-sm md:text-base lg:text-base xl:text-base">
          {{initials}}
          <zondicon
          icon="arrow-thick-down"
          class="h-5 ml-4 sm:ml-10 md:ml-8"/>
        </span>

    </div>

    <span
      class="student-signature"
      v-if="model">
      {{ getInitials }}
    </span>
  </div>
</template>

<script>
  import Zondicon from 'vue-zondicons';
  export default {
    props:['sign','model','signName','initials','getInitials'],
    components:{
      'zondicon': Zondicon,
    },
    data: () => ({}),
    methods: {
      // onSign(){
      //   this.$emit('on-sign');
      // }
    }
  }
</script>

<style scoped>
  span.student-signature{
    font-family: "Cedarville Cursive";
    /*font-size:12pt;*/
  }
  .cursor-pointer{
    cursor: pointer;
  }

</style>