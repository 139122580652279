<template>
  <div>
    <p class="block text-red-900 md:text-xl font-normal md:mb-3">
      In order to take the phlebotomy course you need to attend a CPR course before the first day of class.
      If you have an Allied Health Training Classes or Medial Certification and would like to opt out of the CPR course follow the instructions provided on your student portal once registered for class.
    </p>
    <p class="text-red-900 font-bold mb-5 mt-2"> Please select your CPR course date </p>
    <div class="flex flex-col">
      <label
        v-for="(course, i) in cprCourses"
        :key="i"
        class="ml-4 text-red-900 font-bold mb-2"
      >
        <input
          type="radio"
          class="form-radio h-5 w-5 text-red-700"
          name="cpr_course"
          :value="course.id"
          @change="onChange"
          :checked="course.id === cprCourseId"
        >
        <span class="ml-4">
          {{ `${course.start_date} ${course.hours_start}` | moment('MM/DD/YYYY h:mm a') }}
        </span>

      </label>
      <p class="mt-1 ml-1 text-red-500 text-sm font-semibold italic" >{{ error }}</p>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  export default {
    props: {
      error: String,
      onClearError: Function
    },
    created(){

      if(this.cprCourses.length > 0){
        //console.log(this.cprCourses);
        const [ firstOption ] = this.cprCourses;
        //console.log(firstOption);
        this.updateCprCourseId(parseInt(firstOption.id));
      }
    },
    computed: {
      ...mapState('courseInformation', ['cprCourses']),
      ...mapState('formData', ['cprCourseId']),
    },
    methods: {
      ...mapMutations('formData', ['updateCprCourseId']),
      onChange({ target }){
        this.onClearError();
        this.updateCprCourseId(parseInt(target.value));
      }
    }
  }
</script>

<style>

</style>