<template>
  <div class="flex justify-center p-2 md:p-4 border-2 m-2 md:m-4 rounded-lg">
    <p class="text-left break-normal text-xs md:text-base text-red-900 mr-10">
      Phlebotomy Training Specialists prohibits discrimination against its customers, employees, and applicants for employment on the bases of race, color, national origin, age, disability, sex, gender identity, religion, reprisal, and where applicable, political beliefs, marital status, familial or parental status, sexual orientation, or all or part of an individual's income is derived from any public assistance program, or protected genetic information in employment or in any program or activity conducted or funded by the Phlebotomy Training Specialists.
    </p>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  data: () => ({

  }),
  mounted(){
    this.$nextTick(()=>{
      //console.log('Course Id', this.courseTypeId);
    });
  },
  computed: {
    ...mapState('courseInformation', [
      'name',
      'courseTypeId'
    ]),
  }
}
</script>

<style>

</style>
