

import { ValidationProvider, extend } from 'vee-validate/dist/vee-validate.full.esm';
extend('date', {
  validate(value, args){
    console.log(args);
    const month = parseInt(value.slice(0,2)) - 1;
    const day = parseInt(value.slice(2,4));
    const year = parseInt(value.slice(4,8));
    const birthDate = new Date(year,month,day);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    //console.log(month, day, year, birthDate.toDateString(), age);
    //return false;
    return age > 18 || `We appreciate your interest in taking our course. However, we require all students to be 18 years old or older by the time class starts. Please consider registering for a later date.`;
  },
  params: ['course_date'],
});

extend('custom_email', {
  validate: value => {

    //const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //console.log(emailRegex.test(value));
    return emailRegex.test(value);
  },
  message: 'The {_field_} field must be valid.'
})

export default ValidationProvider;