<template>
  <p class="mt-2" v-html="getMessageHtml">
  </p>
</template>

<script>

  import { mapState } from 'vuex'
  export default {
    data: ()=>({}),
    computed: {
      ...mapState('courseInformation', ['courseType']),
      getMessageHtml(){
        if(this.courseType == 'IV'){
          return `Scrubs are not mandatory, but they are highly suggested.
            They can be any matching solid color.
            Please <span class="font-bold">do not wear any OPEN TOED shoes</span> to class.
            <span class="font-bold">FACE MASK ARE REQUIRED.</span>`;
        }
        else if(this.courseType == 'EKG' || this.courseType == 'ECG'){
          return `Do not lotion your skin the day of class on your chest, wrists, forearms, ankles or calves.
            Shave your chest if you need to.
            Do not wear underwire bras, no metal.
            Sports bras are not good either avoid cleavage.
            Wear loose clothing to class and pants that can be rolled/pulled up to the knee.
            Please <span class="font-bold">do not wear any OPEN TOED shoes</span> to class and no provocative clothing.
            Scrubs are preferred but not mandatory.
            <span class="font-bold">FACE MASK ARE REQUIRED.</span>`;
        }
        else{
          return `As we will be drawing blood your attire should be scrubs, or clothes you don't mind getting blood on in the unlikely event of a spill.
          Please do <span class="font-bold">not wear any OPEN TOED shoes</span> to class and no provocative clothing.
          Scrubs are preferred but not mandatory.`;
        }
      }
    }
  }
</script>

<style>

</style>