<template>
  <div class="hidden sm:block sm:ml-6">

  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
</style>
