<template>
<div>
  <nav class="flex items-center justify-between flex-wrap bg-white p-6">
      <div class="flex items-center flex-shrink-0 text-white mr-6">
          <img class="h-10 sm:h-12" src="https://www.ivtrainingcenter.com/images/iv_logo.png" alt="Phlebotomy USA">
      </div>
      <!-- <div class="absolute inset-y-0 left-0 flex items-center sm:hidden"> -->
      <div class="lg:hidden block flex w-auto">

          <mobile-menu-button @click.native="toggleMenu" :active="hamburgerButton" ></mobile-menu-button>
      </div>
      <!-- Desktop Menu -->
      <div class="hidden w-full lg:block flex-grow lg:flex lg:items-center lg:w-auto">
          <div class="text-sm lg:flex-grow">
             <a href="https://www.ivtrainingcenter.com" class="block mt-4 lg:inline-block lg:mt-0 text-gray-600 hover:text-gray-500 lg:ml-6 mr-6">
                  Home
              </a>
              <a href="https://www.ivtrainingcenter.com/course" class="block mt-4 lg:inline-block lg:mt-0 text-gray-600 hover:text-gray-500 mr-6">
                  I.V. Courses
              </a>
              <a href="https://www.ivtrainingcenter.com/class-dates" class="block mt-4 lg:inline-block lg:mt-0 text-gray-600 hover:text-gray-500 mr-6">
                  Class Dates
              </a>
              <a href="https://www.ivtrainingcenter.com/directions" class="block mt-4 lg:inline-block lg:mt-0 text-gray-600 hover:text-gray-500 mr-6">
                  Directions
              </a>
              <a href="https://www.ivtrainingcenter.com/faq" class="block mt-4 lg:inline-block lg:mt-0 text-gray-600 hover:text-gray-500 mr-6">
                FAQ
              </a>
              <a href="https://www.ivtrainingcenter.com/contact" class="block mt-4 lg:inline-block lg:mt-0 text-gray-600 hover:text-gray-500 mr-6">
                Contact Us
              </a>
              <a href="https://www.ivtrainingcenter.com/phlebotomy-iv" class="block mt-4 lg:inline-block lg:mt-0 text-gray-600 hover:text-gray-500 mr-6">
                Phlebotomy/EKG
              </a>
          </div>
      </div>
      <!-- End Desktop Menu -->
      <!-- Mobile Menu -->
      <div v-if="hamburgerButton" class="w-full lg:hidden">
          <div class="text-md mt-4">
              <a href="https://www.ivtrainingcenter.com" class="hover:bg-gray-200 rounded-lg block px-4 py-3 text-gray-700 hover:text-gray-800 ">
                  Home
              </a>
              <a href="https://www.ivtrainingcenter.com/course" class="hover:bg-gray-200 rounded-lg block px-4 py-3 text-gray-700 hover:text-gray-800 ">
                  I.V. Courses
              </a>
              <a href="https://www.ivtrainingcenter.com/class-dates" class="hover:bg-gray-200 rounded-lg block px-4 py-3 text-gray-700 hover:text-gray-800 ">
                  Class Dates
              </a>
              <a href="https://www.ivtrainingcenter.com/directions" class="hover:bg-gray-200 rounded-lg block px-4 py-3 text-gray-700 hover:text-gray-800 ">
                  Directions
              </a>
              <a href="https://www.ivtrainingcenter.com/faq" class="hover:bg-gray-200 rounded-lg block px-4 py-3 text-gray-700 hover:text-gray-800 ">
                  FAQ
              </a>
              <a href="https://www.ivtrainingcenter.com/contact" class="hover:bg-gray-200 rounded-lg block px-4 py-3 text-gray-700 hover:text-gray-800 ">
                  Contact Us
              </a>
              <a href="https://www.ivtrainingcenter.com/phlebotomy-iv" class="hover:bg-gray-200 rounded-lg block px-4 py-3 text-gray-700 hover:text-gray-800 ">
                  Phlebotomy/EKG
              </a>

          </div>
      </div>
      <!-- End Mobile Menu -->
  </nav>
</div>
</template>

<script>

import DesktopMenu from '@/components/Layout/NavBar/DesktopMenu.vue';
import MobileMenu from '@/components/Layout/NavBar/MobileMenu.vue';
import MobileMenuButton from '@/components/Layout/NavBar/MobileMenuButton.vue';

export default {
  components: {
    'mobile-menu-button': MobileMenuButton
  },
  methods: {
    toggleMenu: function () {
      this.hamburgerButton = !this.hamburgerButton;
    }
  },
  data: function() {
    return {
      hamburgerButton: false
    }
  }

}
</script>

<style>

</style>
