<template>
<div v-if="valid">

    <side-message class="p-8" v-if="!firstTest && (currentForm == numForms - 4 && courseState == 'CA')">
      <ca-message></ca-message>
    </side-message>

    <side-message class="p-8" v-if="!diplomaProof && (currentForm == numForms - 4 && courseState == 'NV')">
      <nv-message />
    </side-message>

    <side-message class="p-8" v-if="!firstTest && (currentForm == numForms - 4 && courseState == 'TN')">
      <tn-message></tn-message>
    </side-message>

    <side-message class="p-8" v-if="!firstTest && (currentForm == numForms - 4 && courseType == 'IV')">
      <iv-message></iv-message>
    </side-message>

    <CourseCoupon class="pr-4" v-if="currentForm == (numForms - 1) && isAvailableDiscountCoupon"/>

    <price-table class="pb-8 pt-0 p pr-4" v-if="currentForm == (numForms - 1)"></price-table>



    <information-card :currentForm="currentForm" :numForms="numForms"></information-card>

    <success-message-side-bar v-if="currentForm == numForms" class="p-8"></success-message-side-bar>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import SideMessage from './Elements/SideMessage.vue'
import CAMessage from './Elements/Messages/CAMessage.vue'
import NVMessage from './Elements/Messages/NVMessage.vue'
import TNMessage from './Elements/Messages/TNMessage.vue'
import IVMessage from './Elements/Messages/IVMessage';
import CourseCoupon from './Elements/CourseCoupon.vue';
import PriceTable from './Elements/PriceTable.vue'
import SuccessMessageSideBar from './Elements/SuccessMessageSideBar.vue'
import InformationCard from './Elements/InformationCard.vue'

export default {
  props: ['currentForm', 'numForms'],
  components: {
    'side-message' : SideMessage,
    'ca-message' : CAMessage,
    'nv-message' : NVMessage,
    'tn-message' : TNMessage,
    'iv-message': IVMessage,
    CourseCoupon,
    'price-table' : PriceTable,
    'success-message-side-bar' : SuccessMessageSideBar,
    'information-card' : InformationCard
  },
  computed: {
    ...mapState('formData', {
      firstTest: 'firstTest', diplomaProof: 'diplomaProof'
    }),
    ...mapState('courseInformation', {
      valid: 'valid',
      courseState: 'state',
      availableForPayment: 'availableForPayment',
      courseType: 'courseType'
    }),
    ...mapGetters('courseInformation', ['isAvailableDiscountCoupon'])
  }
}
</script>

<style scoped>
</style>
