<template>
  <div class="checkout">
    <title-bar
      :title="valid == 'loading' ? 'Checkout loading…' : 'Checkout'"
    ></title-bar>
    <subtitle-bar v-if="state === 'OR'"/>
    <checkout></checkout>
    <vue-tailwind-modal
      :showing="payCustomShowModal"
      @close="onCloseModal"
      :showClose="true"
    >
      <!-- Put your modal content here -->
      <div class="flex">
        <div class="w-full border-b-2 border-green-700 mt-0 text-center text-green-700 h-10">
          <p class="text-lg font-semibold">Enter the amount to be paid</p>
        </div>
      </div>
      <div class="flex justify-center">
        <div class="w-3/5 md:w-2/5 text-center text-green-700">
          <form class="px-8 pt-6 pb-8" @submit.prevent>
            <div class="mb-4">
              <label class="block text-xs mb-2 font-medium" for="username" v-if="state === `TN`">
                The amount must be between
                the minimum of ${{depositAmount}} and your
                total of ${{withExamFee ? realTotalPrice : realTotalPrice}}
              </label>
              <label class="block text-xs mb-2 font-medium" for="username" v-else>
                The amount must be between
                the minimum of ${{depositAmount}} and your
                total of ${{realTotalPrice}}
                <span v-if="discountCode.isValid">( Coupon {{ discountCode.name }} applied )</span>
              </label>
              <input
                :class="[error ? 'border-red-700': 'border-green-700']"
                class="shadow rounded border-2 w-full py-2 px-3 leading-tight"
                type="text"
                :placeholder="range"
                :value="payCustomAmount"
                @keyup="onInput"
                >
              <p v-show="error" class="text-red-500 text-xs italic">
                {{errorMessage}}
              </p>
            </div>
              <div class="flex justify-center">
                <button :disabled="error" @click="onSubmit" class="bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:bg-green focus:shadow-outline focus:border-green-300" type="button">
                  Continue
                </button>
              </div>
          </form>
        </div>
      </div>
    </vue-tailwind-modal>
  </div>
</template>

<script>

import {mapState, mapMutations, mapGetters} from 'vuex';

import TitleBar from '@/components/Layout/TitleBar.vue';
import SubtitleBar from '@/components/Layout/SubtitleBar';
import Checkout from '@/components/Checkout/Checkout.vue';
import VueTailwindModal from 'vue-tailwind-modal';
export default {
  data(){
    return {
      showModal: true,
      error: false,
      errorMessage: ''
    }
  },
  components: {
    TitleBar,
    SubtitleBar,
    Checkout,
     VueTailwindModal,
  },
  computed:{
    ...mapState('formData',['payCustomShowModal','payCustomAmount','payCustom']),
    ...mapState('courseInformation', ['depositAmount','state', 'withExamFee','examFeeCost', 'valid', 'discountCode']),
    ...mapGetters('courseInformation', ['totalPrice', 'discountCodeAmount']),
    realTotalPrice(){
      return (this.totalPrice - this.discountCodeAmount);
      // return ((this.totalPrice - this.discountCodeAmount) < this.depositAmount) ? this.depositAmount : (this.totalPrice - this.discountCodeAmount);
    },
    range(){
      if(this.state === 'TN'){

        return this.withExamFee ? `$${this.depositAmount} - $${this.realTotalPrice}`
          : `$${this.depositAmount} - $${this.realTotalPrice}`;
      }
      return `$${this.depositAmount} - $${this.realTotalPrice}`;
    },
  },
  methods:{
    ...mapMutations('formData', ['setPayCustomShowModal','setPayCustomAmount','setPayCustom','setPaySelected', 'updatePayFull']),
    onCloseModal(){
      this.error = false;
      this.errorMessage = ``;
      this.setPayCustomAmount('');
      this.updatePayFull(true);
      this.setPayCustom(false);
      this.setPaySelected('payFull')
      this.setPayCustomShowModal(false);
    },
    onSubmit(e){
      //console.log('submit', this.payCustomAmount);

      if(!this.payCustomAmount){
        this.error = true;
        this.errorMessage = `Your amount must be equal or higher than $ ${this.depositAmount}`;
        return;
      }

      if(this.state === 'TN'){
        if(Number.parseInt(this.payCustomAmount) < Number.parseInt(this.depositAmount)){
          this.error = true;
          this.errorMessage = `Your amount must be equal or higher than $ ${this.depositAmount}`;
        }
        else if(Number.parseInt(this.payCustomAmount) > Number.parseInt(this.withExamFee ? (this.realTotalPrice) : this.realTotalPrice)){
          this.error = true;
          this.errorMessage = `Your amount must be equal or lower than $ ${this.withExamFee ? (this.realTotalPrice) : this.realTotalPrice}`;
        }
        else{
          this.error = false;
          this.setPayCustom(true);
          this.setPayCustomShowModal(false);
          this.setPaySelected('payCustom')
        }
      }
      else{
        if(Number.parseInt(this.payCustomAmount) < Number.parseInt(this.depositAmount)){
          this.error = true;
          this.errorMessage = `Your amount must be equal or higher than $ ${this.depositAmount}`;
        }
        else if(Number.parseInt(this.payCustomAmount) > Number.parseInt(this.realTotalPrice)){
          this.error = true;
          this.errorMessage = `Your amount must be equal or lower than $ ${this.realTotalPrice}`;
        }
        else{
          this.error = false;
          this.setPayCustom(true);
          this.setPayCustomShowModal(false);
          this.setPaySelected('payCustom')
        }
      }


      // if(!this.error){
      //   this.setPayCustom(true);
      //   this.setPayCustomShowModal(false);
      //   this.setPaySelected('payCustom')
      // }
      // else{
      //   if(Number.parseInt(this.payCustomAmount) < Number.parseInt(this.depositAmount)){
      //     this.error = true;
      //     this.errorMessage = `Your amount must be equal or higher than $ ${this.depositAmount}`;
      //   }
      //   else if(Number.parseInt(this.payCustomAmount) > Number.parseInt(this.totalPrice)){
      //     this.error = true;
      //     this.errorMessage = `Your amount must be equal or lower than $ ${this.totalPrice}`;
      //   }
      //   else{
      //     this.error = false;
      //     this.setPayCustomAmount(value);
      //   }
      // }
      //this.setPayCustomAmount(this.payCustomAmount);
    },
    onInput(e){
      const reg = new RegExp('^[0-9]+$');
      const value = e.target.value;
      if(reg.test(value)){
        this.setPayCustomAmount(Number.parseInt(value));
        this.error = false;
        this.errorMessage = ''
        // if(Number.parseInt(value) < Number.parseInt(this.depositAmount)){
        //   this.error = true;
        //   this.errorMessage = `Your amount must be equal or higher than $ ${this.depositAmount}`;
        // }
        // else if(Number.parseInt(value) > Number.parseInt(this.totalPrice)){
        //   this.error = true;
        //   this.errorMessage = `Your amount must be equal or lower than $ ${this.totalPrice}`;
        // }
        // else{
        //   this.error = false;
        //   this.setPayCustomAmount(value);
        // }

      }
      else{
        this.error = true;
        this.errorMessage = 'Only numbers.'


      }
      //console.log('blur', this.payCustomAmount);
      //return e.preventDefault();
    }
  }
}
</script>

<style>
  .text-green-ecg {
    color: #4B5668 !important;
  }

  .text-menu-green-ecg {
    color: #718197 !important;
  }

  .bg-green-ecg {
    background-color: #3E8147 !important;
  }

  .border-green-ecg {
    border-color: #3E8147 !important;
  }

  .border-green-hr-ecg{
    border-color: #4B5668 !important;
  }

  .placeholder-green-ecg::placeholder {
    color: #a0a0a0 !important;
  }

</style>


