<template>
  <tbody>
    <!-- <tr class="border-2" v-if="forAll">
        <td class="border-b px-4 py-2">{{ name }}</td>
        <td class="border-b px-4 py-2">$ {{ courseCost }}</td>
    </tr>
    <tr class="border-2" v-if="onlyIL">
      <td class="border-b px-4 py-2">Class Tuition</td>
      <td class="border-b px-4 py-2">$ 760</td>
    </tr>
    <tr class="border-2" v-if="onlyTN">
      <td class="border-b px-4 py-2">Books</td>
      <td class="border-b px-4 py-2">$ 35</td>
    </tr>
    <tr class="border-2" v-if="onlyTN">
      <td class="border-b px-4 py-2">Supplies/Equipment Fee</td>
      <td class="border-b px-4 py-2">$ 180</td>
    </tr> -->
    <!-- oldcomment -->
    <!--<tr class="border-2" v-if="onlyTN">
      <td class="border-b px-4 py-2">Class Tuition</td>
      <td class="border-b px-4 py-2">$ 995</td>
    </tr>-->
    <!-- oldcomment -->

    <!-- <tr class="border-2" v-if="onlyIL">
      <td class="border-b px-4 py-2">Supplies, Equipment</td>
      <td class="border-b px-4 py-2">$ 150</td>
    </tr>
    <tr class="border-2" v-if="onlyIL">
      <td class="border-b px-4 py-2">Registration Fee *</td>
      <td class="border-b px-4 py-2">$ 200</td>
    </tr>
    <tr class="border-2" v-if="onlyIL">
      <td class="border-b px-4 py-2 font-bold">Total For Tuition</td>
      <td class="border-b px-4 py-2 font-bold">
        ${{ totalPrice - examFeeCost}}
      </td>
    </tr>
    <tr class="border-2" v-if="onlyTN">
      <td class="border-b px-4 py-2 font-bold">Total For Tuition</td>
      <td class="border-b px-4 py-2 font-bold">
        ${{ totalPrice - examFeeCost}}
      </td>
    </tr>
    <slot name="coupon-breakdown" v-bind:discountCode="discountCode"></slot>
    <tr>
      <td class="border-b px-4 py-2"><p class="px-4 py-2">&nbsp;</p></td>
      <td class="border-b px-4 py-2"><p class="px-4 py-2">&nbsp;</p></td>
    </tr>
    <tr v-if="onlyTN && courseCost >  0" class="border-2 bg-blue-100 text-blue-800 font-semibold" >
      <td class="border-b px-4 py-2">Due by mid-class</td>
      <td class="border-b px-4 py-2">$ {{ totalPrice - examFeeCost}}</td>
    </tr>
    <tr v-if="hasNationalExam" class="border-2">
      <td class="border-b px-4 py-2">National Exam Fee <span v-if="onlyTN">(optional)</span></td>
      <td class="border-b px-4 py-2">$ {{ examFeeCost }}</td>
    </tr> -->
    <!-- oldComment -->
    <!-- <tr v-if="onlyTN && hasNationalExam" class="border-2">
      <td class="border-b px-4 py-2">
        <span :class="[ !withExamFee ? `line-through` : `` ]">National Exam Fee (optional)</span>
      </td>
      <td class="border-b px-4 py-2">
        <span :class="[ !withExamFee ? `line-through` : `` ]">$ {{ examFeeCost }}</span>
      </td>
    </tr> -->
    <!-- oldComment -->
    <!-- <tr v-if="insuranceCost > 0" class="border-2">
      <td class="border-b px-4 py-2">Insurance</td>
      <td class="border-b px-4 py-2">$ {{ insuranceCost }}</td>
    </tr>
    <tr v-if="externshipCost > 0" class="border-2">
      <td class="border-b px-4 py-2">Document Processing Fee</td>
      <td class="border-b px-4 py-2">$ {{ externshipCost }}</td>
    </tr> -->
    <tr v-for="(fee_item, i) in state_fee_items" :key="i" class="border-2">
      <td class="border-b px-4 py-2">
        {{ fee_item.custom_name ? fee_item.custom_name : fee_item.fee.name }}
      </td>
      <td class="border-b px-4 py-2">$ {{ fee_item.amount }}</td>
    </tr>
    <tr>
      <td class="border-b px-4 py-2 text-center" colspan="2">
        <label class="font-bold">
          Total Payment
        </label>
      </td>
    </tr>
    <!-- <tr :class="[ payFull ? 'text-gray-800 bg-gray-100 font-semibold': 'line-through text-gray-600' ]" class="">
      <td class="border-b px-4 py-2">Total for Tuition & Exam Fee</td>
      <td class="border-b px-4 py-2">$ {{ totalPrice }}</td>
    </tr> -->
    <tr
      v-if="payFull && !payCustom"
      class="border-2 font-semibold"
      :class="{ 'text-green-900 bg-green-100': !discountCode.isValid }"
    >
      <td
        v-if="forAll || onlyIL"
        class="px-4 py-2"
        :class="{
          'border-t-2 border-b-2 border-l-2 border-green-400': !discountCode.isValid,
        }"
      >
        Tuition & Exam Fee
      </td>
      <td
        v-if="onlyTN"
        class="px-4 py-2"
        :class="{
          'border-t-2 border-b-2 border-l-2 border-green-400': !discountCode.isValid,
        }"
      >
        Tuition <span v-if="withExamFee">& Exam Fee</span>
      </td>
      <td
        v-if="forAll || onlyIL"
        class="px-4 py-2"
        :class="{
          'border-t-2 border-b-2 border-r-2 border-green-400': !discountCode.isValid,
        }"
      >
        $ {{ totalPrice }}
      </td>
      <td
        v-if="onlyTN"
        class="px-4 py-2"
        :class="{
          'border-t-2 border-b-2 border-r-2 border-green-400': !discountCode.isValid,
        }"
      >
        $ {{ totalPrice }}
      </td>
    </tr>
    <slot
      name="total-coupon-breakdown"
      v-bind:discountCode="discountCode"
    ></slot>
    <tr
      v-if="!payFull && !payCustom && availableForPayment"
      :class="[
        !payFull
          ? 'text-green-900 bg-green-100 font-semibold'
          : 'text-green-900 bg-green-100 font-semibold',
      ]"
    >
      <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">
        Registration Only
      </td>
      <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">
        $ {{ depositAmount }}
      </td>
    </tr>

    <tr
      v-if="payCustom"
      :class="[
        !payFull
          ? 'text-green-900 bg-green-100 font-semibold'
          : 'text-green-900 bg-green-100 font-semibold',
      ]"
    >
      <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">
        Your Payment
      </td>
      <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">
        $ {{ payCustomAmount }}
      </td>
    </tr>

    <tr v-if="onlyTN" :class="['text-green-900 bg-green-100 font-semibold']">
      <td
        class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2"
        v-if="availableForPayment"
      >
        + Application
      </td>
      <td
        class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2"
        v-else
      >
        Application
      </td>
      <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">
        $ {{ applicationFee }}
      </td>
    </tr>

    <tr v-if="onlyTN" :class="['text-green-900 bg-green-100 font-semibold']">
      <td
        class="border-t-2 border-l-2 border-green-400 px-4 py-2"
        :class="[paySelected === 'payFull' ? 'border-b-2' : '']"
      >
        Total Payment Today
      </td>
      <td
        class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2"
        v-if="paySelected === 'payFull'"
      >
        $
        {{
          discountCodeAmount >= totalAmount
            ? 0
            : totalAmount + applicationFee - discountCodeAmount
        }}
      </td>
      <!-- <td
        class="border-t-2 border-r-2 border-green-400 px-4 py-2"
        v-else-if="paySelected === 'payNotFull' || paySelected === 'payCustom'"
      >
        $ {{ totalAmount + applicationFee }}
      </td>
      <td class="border-t-2 border-r-2 border-green-400 px-4 py-2" v-else>
        $ {{ discountCodeAmount >= totalAmount ? 0 : applicationFee }}
      </td> -->
      <td
        class="border-t-2 border-r-2 border-green-400 px-4 py-2"
        v-else-if="paySelected === 'payNotFull' || paySelected === 'payCustom'"
      >
        $ {{ totalAmount + applicationFee }}
      </td>
      <td class="border-t-2 border-r-2 border-green-400 px-4 py-2" v-else>
        $ {{ applicationFee }}
      </td>
    </tr>

    <tr
      v-if="!payFull && !payCustom"
      class="bg-red-100 text-red-900 font-semibold"
    >
      <td class="border-t-2 border-b-2 border-l-2 border-red-400 px-4 py-2">
        Remaining Balance
      </td>
      <td
        v-if="forAll || onlyIL"
        class="border-t-2 border-b-2 border-r-2 border-red-400 px-4 py-2"
      >
        $ {{ remainingBalance }}
      </td>
      <!-- <td
        v-if="onlyTN"
        class="border-t-2 border-b-2 border-r-2 border-red-400 px-4 py-2"
      >
        $ {{ totalRemainingBalance }}
      </td> -->
      <td
        v-if="onlyTN"
        class="border-t-2 border-b-2 border-r-2 border-red-400 px-4 py-2"
      >
        $ {{ remainingBalance }}
      </td>
    </tr>

    <tr v-if="payCustom" class="bg-red-100 text-red-900 font-semibold">
      <td class="border-t-2 border-b-2 border-l-2 border-red-400 px-4 py-2">
        Remaining Balance
      </td>
      <td
        v-if="forAll || onlyIL"
        class="border-t-2 border-b-2 border-r-2 border-red-400 px-4 py-2"
      >
        $ {{ remainingBalance }}
      </td>
      <!-- <td
        v-if="onlyTN"
        class="border-t-2 border-b-2 border-r-2 border-red-400 px-4 py-2"
      >
        $ {{ totalRemainingBalance }}
      </td> -->
      <td
        v-if="onlyTN"
        class="border-t-2 border-b-2 border-r-2 border-red-400 px-4 py-2"
      >
        $ {{ remainingBalance }}
      </td>
    </tr>
    <slot
      name="total-coupon-payment"
      :discountCode="discountCode"
      :totalAmount="totalAmount"
      :discountCodeAmount="discountCodeAmount"
      :paySelected="paySelected"
      :onlyTN="onlyTN"
    ></slot>
  </tbody>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  props: [
    "insuranceCost",
    "externshipCost",
    "payFull",
    "payCustom",
    "payCustomAmount",
    "courseCost",
    "name",
    "totalPrice",
    "depositAmount",
    "remainingBalance",
    "hasNationalExam",
    "examFeeCost",
    "discountCode",
    "discountCodeAmount",
    "paySelected",
    "state",
    "withExamFee",
    "availableForPayment",
    "applicationFee",
    "state_fee_items",
  ],
  computed: {
    ...mapGetters("courseInformation", ["totalAmount"]),
    forAll() {
      return this.state !== "IL" && this.state !== "TN";
    },
    onlyIL() {
      return this.state === "IL";
    },
    onlyTN() {
      return this.state === "TN";
    },
    totalPayment() {
      return this.totalAmount + this.applicationFee;
    },
    totalRemainingBalance() {
      return (
        this.remainingBalance +
        (this.paySelected === "tnApplicacionFee" ? this.applicationFee : 0)
      ); //+ this.applicationFee;
    },
  },
};
</script>
