<template>
<div style="cursor:pointer;" :class="[ selected ? selectedClass : unselectedClass ]" class="w-4/5 lg:w-3/5 md:w-3/5 pl-6 pr-4 mb-3 mr-1 rounded-lg">
    <div class="flex">
      <div class="w-full mt-1">
        <h5 :class="[ selected ? 'text-green-900': 'text-gray-400' ]" class=" font-semibold text-lg leading-tight truncate"><slot name="title"></slot></h5>
        <div :class="[ selected ? 'text-green-800': 'text-gray-400' ]" class="mt-2 text-xs font-medium">
          <slot name="header"></slot>
        </div>
      </div>
      <!-- <div class="flex justify-end w-1/5 ml-2 ">
        <div class="h-8">
          <zondicon icon="checkmark-outline" :class="[ selected ? 'h-8': '' ]" class="fill-current text-green-900"/>
        </div>
      </div> -->
    </div>
    <div :class="[ selected ? 'text-green-700': 'text-gray-400' ]" class="mt-1 mr-1 text-xs font-medium">
      <slot name="text"></slot>
    </div>
</div>
</template>

<script>
import Zondicon from 'vue-zondicons';

export default {
  props: {
    selected: {
      type: Boolean,
      required: true
    },
    variable: {
      type: String,
      default: ''
    },
    selectedClass: {
      type: String,
      default: 'bg-green-200 border-2 border-green-400'
    },
    unselectedClass:{
      type: String,
      default: 'bg-gray-100 border-2 border-gray-400 hover:border-gray-300'
    }
  },
  components: {
    'zondicon': Zondicon
  },
}
</script>

<style scoped>
</style>
