<template>
  <div>
    <div v-if="availableForPayment">
      <div class="flex justify-center mt-5">
        <!-- Deposit Button -->
        <card-button
          @click.native="toggleButton(1)"
          :selected="this.selected == 'payNotFull'"
        >
          <template v-slot:title>
            Pay ${{ depositAmount }} Registration <br />
            + Application Fee ${{ tnApplicationFee }}
          </template>
          <template v-slot:header>
            <span>
              Non-refundable
            </span>
          </template>
          <template v-slot:text></template>
        </card-button>
        <!-- End Deposit Button -->
      </div>
      <div class="flex justify-center">
        <!-- Full Button -->
        <card-button
          @click.native="toggleButton(2)"
          :selected="this.selected == 'payFull'"
        >
          <template v-slot:title>
            Pay in Full ${{ totalPayInFullAmount }} <br />
            + Application Fee ${{
              totalPayInFullAmount === 0 ? 0 : tnApplicationFee
            }}
          </template>
          <template v-slot:header>
            <span>
              ${{ depositAmount + tnApplicationFee }} registration + application
              non-refundable
            </span>
          </template>
          <template v-slot:text></template>
        </card-button>
        <!-- End Full Button -->
      </div>
      <div class="flex justify-center">
        <!-- Full Button -->
        <card-button
          @click.native="toggleButton(3)"
          :selected="this.selected == 'payCustom'"
        >
          <template v-slot:title>Pay another amount</template>
          <template v-slot:header>
            <span>
              Minimum ${{ depositAmount }} registration + application
              non-refundable
            </span>
          </template>
          <template v-slot:text></template>
        </card-button>
        <!-- End Full Button -->
      </div>
    </div>
    <div v-else>
      <div class="flex justify-center mt-5">
        <!-- Deposit Button -->

        <card-button
          @click.native="toggleButton(4)"
          :selected="this.selected == 'tnApplicacionFee'"
        >
          <template v-slot:title>
            Application Fee ${{ tnApplicationFee }}
          </template>
          <template v-slot:header>
            <span>
              Non-refundable
            </span>
          </template>
          <template v-slot:text></template>
        </card-button>
        <!-- End Deposit Button -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

import CardButton from "@/components/Checkout/Elements/CardButton.vue";

export default {
  components: {
    "card-button": CardButton,
  },
  data() {
    return {
      //selected : 'payFull',
      discountCodeData: {},
      showModal: true,
    };
  },
  created() {
    //console.log(this.availableForPayment);
    if (!this.availableForPayment) {
      this.toggleButton(4);
    } else {
      this.toggleButton(1);
    }
  },
  computed: {
    ...mapState("courseInformation", [
      "depositAmount",
      "state",
      "city",
      "withExamFee",
      "examFeeCost",
      "availableForPayment",
      "tnApplicationFee",
      "discountCode",
      "state_fee_total",
    ]),
    ...mapGetters("courseInformation", [
      "totalPrice",
      "totalAmount",
      "discountCodeAmount",
      "remainingBalance",
    ]),
    ...mapState("formData", ["payCustom", "payCustomAmount", "paySelected"]),
    totalPayInFullAmount() {
      if (this.paySelected === "payFull") {
        if (this.discountCodeAmount >= this.state_fee_total)
          return this.state_fee_total - this.discountCodeAmount;
      }
      return this.totalPrice;
    },
    payFull: {
      get() {
        return this.$store.state.formData.payFull;
      },
      set(value) {
        this.$store.commit("formData/updatePayFull", value);
      },
    },
    custom: {
      get() {
        return this.payCustom;
      },
      set(value) {
        this.setPayCustom(value);
      },
    },
    customAmount: {
      get() {
        return this.payCustomAmount;
      },
      set(value) {
        this.setPayCustomAmount(value);
      },
    },
    selected: {
      get() {
        return this.paySelected;
      },
      set(value) {
        this.setPaySelected(value);
      },
    },
  },
  watch: {
    discountCode: {
      handler(newVal) {
        // if(
        //   (this.paySelected === 'payNotFull' && (newVal?.amount / 100) > this.depositAmount) ||
        //   (this.paySelected === 'payCustom' && this.totalAmount < this.depositAmount)
        // ) {
        //   // this.onPayInFull();
        // } else if(this.paySelected === 'payCustom' && this.payCustomAmount > (this.totalPrice - (newVal?.amount / 100))) {
        //   // this.setPayCustomAmount(this.totalPrice - (newVal?.amount / 100))
        //   //console.log('change custom', this.totalPrice - (newVal?.amount / 100));
        // }
        if (newVal.isValid && this.paySelected !== "tnApplicacionFee")
          this.onPayInFull();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("formData", [
      "setPayCustom",
      "setPayCustomAmount",
      "setPayCustomShowModal",
      "setPaySelected",
    ]),
    ...mapMutations("courseInformation", ["setDiscountCode"]),
    onPayInFull() {
      this.selected = "payFull";
      this.setPayCustomAmount("");
      this.setPayCustom(false);
      this.payFull = true;
    },
    toggleButton(btn) {
      if (this.discountCode.isValid) {
        if (btn === 1 && this.depositAmount >= this.remainingBalance) return; // registration only
        if (btn === 3 && this.depositAmount > this.remainingBalance) return; // custom amount
        if (btn === 4 && this.tnApplicationFee > this.remainingBalance) return; // tn application
      }

      switch (btn) {
        case 1:
          //if(this.discountCode.isValid) return;
          // pay deposit
          this.selected = "payNotFull";
          this.setPayCustomAmount("");
          this.setPayCustom(false);
          this.payFull = false;
          // this.discountCodeData = { ...this.discountCode };
          // this.setDiscountCode({
          //   id: null,
          //   name: null,
          //   amount: 0,
          //   isValid: false,
          //   error: '',
          //   success: '',
          // });
          break;
        case 2:
          // pay in full
          this.onPayInFull();
          break;
        case 3:
          // custom amount
          //if(this.discountCode.isValid) return;
          this.selected = "payCustom";
          this.setPayCustomShowModal(true);
          //this.setPayCustomShowModal(false);
          this.setPayCustomAmount("");
          this.setPayCustom(false);
          // this.discountCodeData = { ...this.discountCode };
          // this.setDiscountCode({
          //   id: null,
          //   name: null,
          //   amount: 0,
          //   isValid: false,
          //   error: '',
          //   success: '',
          // });
          break;
        case 4:
          // application fee
          //if(this.discountCode.isValid) return;
          this.selected = "tnApplicacionFee";
          this.setPayCustomAmount("");
          this.setPayCustom(false);
          this.payFull = false;
          // this.discountCodeData = { ...this.discountCode };
          // this.setDiscountCode({
          //   id: null,
          //   name: null,
          //   amount: 0,
          //   isValid: false,
          //   error: '',
          //   success: '',
          // });
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scoped></style>
