<template>
<!-- <div>
  <footer>
      <div
        class="w-full pin-b text-center bg-white p-4"
        :style="style.div"
      >
        <h1 :style="style.h1">{{ phlebsPhoneNumber }}</h1>
				<h4 :style="style.h4">© Utah EKG Training - All Rights Reserved</h4>
    </div>
  </footer>
</div> -->
<div>
  <footer>
    <div class="w-full pin-b block md:flex bg-gray-800 p-8">

      <div class="flex-1 flex md:block text-gray-600 text-left mx-6 mb-8 md:mb-0 justify-center">
          <div class="mx-3"><a class="hover:text-gray-500" href="#">State Approvals</a></div>
          <div class="mx-3"><a class="hover:text-gray-500" href="#">Careers with PTS</a></div>
          <div class="mx-3"><a class="hover:text-gray-500" href="#">Contact Us</a></div>
      </div>

      <div class="flex-1 mb-8 md:mb-0">
          <div class="flex justify-center my-3">
              <img class=" h-12 rounded-md" src="https://ecgtrainingspecialists.com/wp-content/themes/phlebotomy_usa/images/logo.jpg" alt="Phlebotomy USA">
          </div>
      </div>

        <div class="flex-1 mx-6">
            <div class="flex justify-center my-3">
              <shopper-approved></shopper-approved>
            </div>
        </div>
    </div>
      <div class="w-full pin-b text-center bg-gray-900 p-4">
          <a class="text-gray-600">© Phlebotomy Training Specialists 1993-2020 - All Rights Reserved</a>
    </div>
  </footer>
</div>
</template>


<script>
  import ShopperApproved from './ShopperApproved.vue'
  import { mapState } from 'vuex';
  export default {
    components: {
      'shopper-approved': ShopperApproved
    },
    data: ()=>({
      style: {
        div: {
          'box-shadow': '0px -6px 5px 0px rgba(0,0,0,0.3)',
        },
        h1: {
          'color': '#0A457A',
          'font-size': '2em',
          'font-weight': '500',
          'line-height': '1.2',
        },
        h4: {
          'font-size': '1em',
          'color': '#404040',
          'font-weight': '500',
          'line-height': '1.2',
        }
      }
    }),
    computed: {
      ...mapState(['phlebsPhoneNumber']),
    }

  }
</script>

