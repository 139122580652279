<template>
<div>

  <div v-if="title">
    <p class="font-bold text-lg">
      Thank you for your interest.
    </p>
    <p class="mt-6">
      But a High School diploma or High School equivalency is necessary in order to take this class.
    </p>
  </div>


</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props:{
    title:{
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(['phlebsPhoneNumber']),
  }
}
</script>
