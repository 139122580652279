<template>
  <div class="bg-gray-300" id="app">
    <div
      class="flex flex-col justify-between max-w-5xl mx-auto"
      :class="[valid === 'loading' ? 'h-screen' : '']"
      id="app"
    >
      <div id="nav" v-if="valid !== 'loading'">
        <nav-bar-iv v-if="courseTypeId == 4"></nav-bar-iv>
        <nav-bar-ekg v-else-if="courseTypeId == 3"></nav-bar-ekg>
        <nav-bar-ecg v-else-if="courseTypeId == 11"></nav-bar-ecg>
        <nav-bar v-else></nav-bar>
      </div>
      <div class="bg-white p-12" v-else></div>

      <div class="mb-auto bg-white">
        <router-view />
      </div>

      <div id="footer" v-if="valid !== 'loading'">
        <v-footer-iv v-if="courseTypeId == 4"></v-footer-iv>
        <v-footer-ekg v-else-if="courseTypeId == 3"></v-footer-ekg>
        <v-footer-ecg v-else-if="courseTypeId == 11"></v-footer-ecg>
        <v-footer v-else></v-footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
//import { hashValue } from "@/utils";

import NavBar from "@/components/Layout/NavBar.vue";
import NavBarIV from "@/components/Layout/NavBarIV.vue";
import NavBarEKG from "@/components/Layout/NavBarEKG";
import NavBarECG from "@/components/Layout/NavBarECG.vue";
import VFooter from "@/components/Layout/VFooter.vue";
import VFooterIV from "@/components/Layout/VFooterIV.vue";
import VFooterEKG from "@/components/Layout/VFooterEKG.vue";
import VFooterECG from "@/components/Layout/VFooterECG.vue";

export default {
  components: {
    NavBar,
    "nav-bar-iv": NavBarIV,
    "nav-bar-ekg": NavBarEKG,
    "nav-bar-ecg": NavBarECG,
    VFooter,
    "v-footer-iv": VFooterIV,
    "v-footer-ekg": VFooterEKG,
    "v-footer-ecg": VFooterECG,
  },
  computed: {
    ...mapState("courseInformation", ["valid", "courseTypeId", "courseType"]),
  },
  mounted() {
    //console.log(process.env);
    //hashValue("frfrf").then((value) => console.log(value));
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    if (process.env.NODE_ENV == "production") {
      gtag("js", new Date());
      gtag("config", "AW-1068002820");
      console.log("google ads");
    }
  },
};
</script>

<style src="@/assets/tailwind.css"></style>
