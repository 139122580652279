<template>
  <tbody>
    <tr class="border-2">
        <td class="border-b px-4 py-2">{{ name }}</td>
        <td class="border-b px-4 py-2">$ {{ courseCost }}</td>
    </tr>
    <slot name="coupon-breakdown" v-bind:discountCode="discountCode"></slot>
    <!-- <tr>
      <td class="border-b px-4 py-2"><p class="px-4 py-2">&nbsp;</p></td>
      <td class="border-b px-4 py-2"><p class="px-4 py-2">&nbsp;</p></td>
    </tr>
    <tr v-if="hasNationalExam" class="border-2">
      <td class="border-b px-4 py-2">National Exam Fee</td>
      <td class="border-b px-4 py-2">$ {{ examFeeCost }}</td>
    </tr> -->
    <tr v-if="insuranceCost > 0" class="border-2">
      <td class="border-b px-4 py-2">Insurance</td>
      <td class="border-b px-4 py-2">$ {{ insuranceCost }}</td>
    </tr>
    <tr>
      <td class="border-b px-4 py-2 text-center" colspan="2">
        <label class="font-bold">
          Total Payment
        </label>
      </td>
    </tr>
    <!-- <tr :class="[ payFull ? 'text-gray-800 bg-gray-100 font-semibold': 'line-through text-gray-600' ]" class="">
      <td class="border-b px-4 py-2">Total for Tuition & Exam Fee</td>
      <td class="border-b px-4 py-2">$ {{ totalPrice }}</td>
    </tr> -->
    <tr v-if="payFull && !payCustom" :class="[payFull ? 'text-green-900 bg-green-100 font-semibold': 'text-green-900 bg-green-100 font-semibold' ]">
      <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">Total</td>
      <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">$ {{ totalPrice }}</td>
    </tr>

    <tr v-if="!payFull && !payCustom" :class="[!payFull ? 'text-green-900 bg-green-100 font-semibold': 'text-green-900 bg-green-100 font-semibold' ]">
      <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">Registration Only</td>
      <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">$ {{ depositAmount }}</td>
    </tr>

    <tr v-if="payCustom" :class="[!payFull ? 'text-green-900 bg-green-100 font-semibold': 'text-green-900 bg-green-100 font-semibold' ]">
      <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">Your Payment</td>
      <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">$ {{ depositAmount }}</td>
    </tr>

    <tr v-if="!payFull && !payCustom" class="bg-red-100 text-red-900 font-semibold">
      <td class="border-t-2 border-b-2 border-l-2 border-red-400 px-4 py-2">Remaining Balance</td>
      <td class="border-t-2 border-b-2 border-r-2 border-red-400 px-4 py-2">$ {{ remainingBalance }}</td>
    </tr>

    <tr v-if="payCustom" class="bg-red-100 text-red-900 font-semibold">
      <td class="border-t-2 border-b-2 border-l-2 border-red-400 px-4 py-2">Remaining Balance</td>
      <td class="border-t-2 border-b-2 border-r-2 border-red-400 px-4 py-2">$ {{ remainingBalance }}</td>
    </tr>

  </tbody>
</template>

<script>

export default {
  props:[
    'insuranceCost',
    'payFull',
    'payCustom',
    'payCustomAmount',
    'courseCost',
    'name',
    'totalPrice',
    'depositAmount',
    'remainingBalance',
    'hasNationalExam',
    'examFeeCost',
    'discountCode'
    ],
  data(){
    return {

    }
  },
  mounted(){

  },
}
</script>