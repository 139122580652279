export const hashValue = async (message, algorithm = "SHA-256") => {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hash = await window.crypto.subtle.digest(algorithm, data);

  const hexes = [],
    view = new DataView(hash);
  for (let i = 0; i < view.byteLength; i += 4)
    hexes.push(("00000000" + view.getUint32(i).toString(16)).slice(-8));

  return hexes.join("");
};
