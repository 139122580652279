var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"text-xl font-semibold sm:text-3xl sm:font-normal leading-normal mt-2",class:{
      ..._vm.formCssClasses('paragraph'),
    }},[_vm._v(" English Proficiency ")]),_c('hr',{staticClass:"mt-5",class:{
      ..._vm.formCssClasses('hr'),
    }}),_c('h3',{staticClass:"text-xl font-normal leading-normal mt-8 text-red-900",class:{
      ..._vm.formCssClasses('paragraph'),
    }},[_vm._v(" Can you speak and read english proficiently? ")]),_c('div',{staticClass:"flex justify-center my-10"},[_c('button',{staticClass:"focus:outline-none border-2 px-8 py-4 mb-3 mr-6 rounded-lg",class:[ _vm.yes ? 'bg-red-300 font-semibold border-red-500': 'bg-red-100 hover:bg-red-200 border-red-200 hover:border-red-300' ],on:{"click":_vm.clickYes}},[_c('h5',{staticClass:"text-lg leading-tight truncate",class:[ _vm.yes ? 'text-green-900': 'text-red-900' ]},[_vm._v("YES")])]),_c('button',{staticClass:"lg:hidden focus:outline-none border-2 px-8 py-4 mb-3 mr-6 rounded-lg",class:[ _vm.no ? 'bg-red-300 font-semibold border-red-500': 'bg-red-100 hover:bg-red-200 border-red-200 hover:border-red-300' ],on:{"click":_vm.showAlert}},[_c('h5',{staticClass:"text-lg leading-tight truncate",class:[ _vm.no ? 'text-green-900': 'text-red-900' ]},[_vm._v("NO")])]),_c('button',{staticClass:"hidden lg:inline-block focus:outline-none border-2 px-8 py-4 mb-3 mr-6 rounded-lg",class:[ _vm.no ? 'bg-red-300 font-semibold border-red-500': 'bg-red-100 hover:bg-red-200 border-red-200 hover:border-red-300' ],on:{"click":_vm.clickNo}},[_c('h5',{staticClass:"text-lg leading-tight truncate",class:[ _vm.no ? 'text-green-900': 'text-red-900' ]},[_vm._v("NO")])])]),(!_vm.firstTest)?_c('side-message',{staticClass:"lg:hidden p-2 -mt-8 pb-8",attrs:{"color":"red"}},[_c('ca-message',{attrs:{"title":false}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }