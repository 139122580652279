<template>
  <div class="text-gray-700">
    <table class="table-fixed w-full">
      <thead>
        <tr>
          <th class="w-3/4 px-4 py-2">Concept</th>
          <th class="w-1/4 px-4 py-2">Cost</th>
        </tr>
      </thead>
      <tbody v-if="courseCost == 0">
        <tr>
          <td class="border px-4 py-2">Total</td>
          <td class="border px-4 py-2">$ {{ totalPrice }}</td>
        </tr>
      </tbody>

      <!--
    <price-table-il v-else-if="state =='IL' && (courseType !== 'IV' && courseType !== 'EKG')"
      :insuranceCost="insuranceCost"
      :payFull="payFull"
      :payCustom="payCustom"
      :payCustomAmount="payCustomAmount"
      :courseCost="courseCost"
      :name="name"
      :totalPrice="totalPrice"
      :depositAmount="depositAmount"
      :remainingBalance="remainingBalance"
      :hasNationalExam="hasNationalExam"
      :examFeeCost="examFeeCost"
      :discountCode="discountCode"
    >
      <template v-slot:coupon-breakdown="{discountCode}">
        <tr v-if="discountCode.isValid" class="border-2 text-green-900 bg-green-100 font-semibold">
          <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">Coupon {{ discountCode.name }}</td>
          <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">-$ {{ discountCode.amount }} </td>
        </tr>
      </template>
    </price-table-il>
    -->
      <!--
    <price-table-tn v-else-if="state =='TN' && (courseType !== 'IV' && courseType !== 'EKG')"
      :insuranceCost="insuranceCost"
      :payFull="payFull"
      :payCustom="payCustom"
      :payCustomAmount="payCustomAmount"
      :courseCost="courseCost"
      :name="name"
      :totalPrice="totalPrice"
      :depositAmount="depositAmount"
      :remainingBalance="remainingBalance"
      :hasNationalExam="hasNationalExam"
      :examFeeCost="examFeeCost"
      :availableForPayment="availableForPayment"
      :with-exam-fee="withExamFee"
      :application-fee="tnApplicationFee"
      :pay-selected="paySelected"
      :discountCode="discountCode"
    >
      <template v-slot:coupon-breakdown="{discountCode}">
        <tr v-if="discountCode.isValid" class="border-2 text-green-900 bg-green-100 font-semibold">
          <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">Coupon {{ discountCode.name }}</td>
          <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">-$ {{ discountCode.amount }} </td>
        </tr>
      </template>
    </price-table-tn>
    -->
      <price-table-ptb
        v-else-if="courseType !== 'IV' && courseType !== 'EKG'"
        :insuranceCost="insuranceCost"
        :externship-cost="externshipCost"
        :payFull="payFull"
        :payCustom="payCustom"
        :payCustomAmount="payCustomAmount"
        :courseCost="courseCost"
        :name="name"
        :totalPrice="totalPrice"
        :depositAmount="depositAmount"
        :remainingBalance="remainingBalance"
        :hasNationalExam="hasNationalExam"
        :examFeeCost="examFeeCost"
        :discountCode="discountCode"
        :discountCodeAmount="discountCodeAmount"
        :paySelected="paySelected"
        :state="state"
        :with-exam-fee="withExamFee"
        :availableForPayment="availableForPayment"
        :applicationFee="tnApplicationFee"
        :state_fee_items="state_fee_items"
      >
        <template v-slot:coupon-breakdown="{ discountCode }">
          <tr
            v-if="discountCode.isValid"
            class="border-2 text-green-900 bg-green-100 font-semibold"
          >
            <td
              class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2"
            >
              Coupon "{{ discountCode.name }}"
            </td>
            <td
              class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2"
            >
              -$ {{ discountCode.amount }}
            </td>
          </tr>
        </template>
        <template v-slot:total-coupon-breakdown="{ discountCode }">
          <tr v-if="discountCode.isValid" class="border-2 font-semibold">
            <td class="px-4 py-2">Coupon "{{ discountCode.name }}"</td>
            <td class="px-4 py-2">-$ {{ discountCode.amount }}</td>
          </tr>
        </template>
        <template
          v-slot:total-coupon-payment="{
            discountCode,
            totalAmount,
            discountCodeAmount,
            paySelected,
            onlyTN,
          }"
        >
          <tr
            v-if="discountCode.isValid && paySelected == 'payFull' && !onlyTN"
            class="border-2 text-green-900 bg-green-100 border-2 font-semibold"
          >
            <td
              class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2"
            >
              Total Payment Today
            </td>
            <td
              class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2"
            >
              $ {{ totalAmount - discountCodeAmount }}
            </td>
          </tr>
        </template>
      </price-table-ptb>

      <price-table-iv
        v-else-if="courseType === 'IV'"
        :insuranceCost="insuranceCost"
        :payFull="payFull"
        :payCustom="payCustom"
        :payCustomAmount="payCustomAmount"
        :courseCost="courseCost"
        :name="name"
        :totalPrice="totalPrice"
        :depositAmount="depositAmount"
        :remainingBalance="remainingBalance"
        :hasNationalExam="hasNationalExam"
        :examFeeCost="examFeeCost"
        :discountCode="discountCode"
        :state_fee_items="state_fee_items"
      >
        <template v-slot:coupon-breakdown="{ discountCode }">
          <tr
            v-if="discountCode.isValid"
            class="border-2 text-green-900 bg-green-100 font-semibold"
          >
            <td
              class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2"
            >
              Coupon {{ discountCode.name }}
            </td>
            <td
              class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2"
            >
              -$ {{ discountCode.amount }}
            </td>
          </tr>
        </template>
      </price-table-iv>

      <price-table-ekg
        v-else-if="courseType === 'EKG'"
        :insuranceCost="insuranceCost"
        :payFull="payFull"
        :payCustom="payCustom"
        :payCustomAmount="payCustomAmount"
        :courseCost="courseCost"
        :name="name"
        :totalPrice="totalPrice"
        :depositAmount="depositAmount"
        :remainingBalance="remainingBalance"
        :hasNationalExam="hasNationalExam"
        :examFeeCost="examFeeCost"
        :discountCode="discountCode"
      >
        <template v-slot:coupon-breakdown="{ discountCode }">
          <tr
            v-if="discountCode.isValid"
            class="border-2 text-green-900 bg-green-100 font-semibold"
          >
            <td
              class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2"
            >
              Coupon "{{ discountCode.name }}"
            </td>
            <td
              class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2"
            >
              -$ {{ discountCode.amount }}
            </td>
          </tr>
        </template>
      </price-table-ekg>

      <!--<price-table-all v-else-if="courseType !== 'IV' && courseType !== 'EKG'"
        :insuranceCost="insuranceCost"
        :payFull="payFull"
        :payCustom="payCustom"
        :payCustomAmount="payCustomAmount"
        :courseCost="courseCost"
        :name="name"
        :totalPrice="totalPrice"
        :depositAmount="depositAmount"
        :remainingBalance="remainingBalance"
        :hasNationalExam="hasNationalExam"
        :examFeeCost="examFeeCost"
        :discountCode="discountCode"
        :externship-cost="externshipCost"
        :discountCodeAmount="discountCodeAmount"
      >
        <template v-slot:coupon-breakdown="{discountCode}">
          <tr v-if="discountCode.isValid" class="border-2 text-green-900 bg-green-100 font-semibold">
            <td class="border-t-2 border-b-2 border-l-2 border-green-400 px-4 py-2">Coupon Tuition</td>
            <td class="border-t-2 border-b-2 border-r-2 border-green-400 px-4 py-2">-$ {{ discountCode.amount }} </td>
          </tr>
        </template>
      </price-table-all>-->
    </table>
    <!--  <p class="mx-6 mt-4" v-if="state =='IL'">Fees with <span class="font-bold text-xl">*</span> are due before the first day of class.</p>-->
    <p class="bg-red-100 text-red-900 font-semibold border-2 mt-3 px-2">
      Students will be required to be paid in full at the half-way point of
      class.
    </p>
  </div>
</template>

<script>
import PriceTableIL from "./PriceTable/PriceTableIL";
import PriceTableTN from "./PriceTable/PriceTableTN";
//import PriceTableAll from './PriceTable/PriceTableAll';
import PriceTableIV from "./PriceTable/PriceTableIV";
import PriceTableEKG from "./PriceTable/PriceTableEKG";
import PriceTablePTB from "./PriceTable/PriceTablePTB";

import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  components: {
    "price-table-il": PriceTableIL,
    "price-table-tn": PriceTableTN,
    "price-table-ptb": PriceTablePTB,
    "price-table-iv": PriceTableIV,
    "price-table-ekg": PriceTableEKG,
    //'price-table-ca': PriceTableCA,
  },
  computed: {
    // currentProps(){
    //   return {
    //     insuranceCost,
    //     payFull,
    //     courseCost,
    //     name,
    //     totalPrice,
    //     depositAmount,
    //     remainingBalance,
    //     hasNationalExam,
    //   }
    // },
    ...mapState("courseInformation", [
      "state",
      "name",
      "courseCost",
      "examFeeCost",
      "insuranceCost",
      "depositAmount",
      "availableForPayment",
      "externshipCost",
      "courseType",
      "withExamFee",
      "tnApplicationFee",
      "discountCode",
      "state_fee_items",
      "state_fee_total",
    ]),
    ...mapGetters("courseInformation", [
      "remainingBalance",
      "totalPrice",
      "discountCodeAmount",
      //'courseCost',
    ]),
    ...mapState("formData", [
      "payFull",
      "hasNationalExam",
      "payCustom",
      "payCustomAmount",
      "paySelected",
    ]),
  },
};
</script>
