<template>
  <div :class="{ ...formCssClasses('paragraph') }" >
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    color: {
      type: String,
      default: 'gray'
    }
  },
  computed: {
    ...mapGetters(['formCssClasses']),
  }
}
</script>

<style scoped>
</style>
