<template>
  <div>
    <h3
      class="text-xl font-semibold sm:text-3xl sm:font-normal leading-normal mt-2"
    >
      Pre-Enrollment Checklist
    </h3>
    <hr class="border-red-300 mt-5" />
    <p class="text-xs md:text-base mt-6">
      The Kentucky Commission on Proprietary Education requires all students to
      complete a Pre-Enrollment checklist prior to enrollment. Please download a
      copy of the Course Catalog and Enrollment Agreement, link below, and
      initial all items.
    </p>
    <div class="flex justify-around mt-2 mb-0">
      <div class="mb-4 pt-0">
        <a
          href="https://admin.phlebotomyusa.com/pdf/KY-enrollment/PTS-Kentucky-Course-Catalog.pdf"
          target="_blank"
        >
          <img
            class="w-8 md:w-12 ml-6 md:ml-10"
            src="https://admin.phlebotomyusa.com/images/icon-pdf-small.png"
          />
          <p class="text-xs md:text-base underline">
            Course Catalog
          </p>
        </a>
      </div>
      <div class="mb-4 pt-0">
        <a
          href="https://admin.phlebotomyusa.com/pdf/KY-enrollment/KY-Enrollment-Packet.pdf"
          target="_blank"
        >
          <img
            class="w-8 md:w-12 ml-8 md:ml-12"
            src="https://admin.phlebotomyusa.com/images/icon-pdf-small.png"
          />
          <p class="text-xs md:text-base underline">
            Enrollment Agreement
          </p>
        </a>
      </div>
    </div>
    <validation-observer ref="formPreEnrollment" v-slot="{ handleSubmit }">
      <div v-for="(check, index) in checks" :key="index">
        <div class="flex items-end mt-2 md:mt-4">
          <pre-enrollment-check
            :sign="returnModel(check.signName)"
            :model="returnModel(check.name)"
            :initials="initials"
            :getInitials="getInitials"
            @on-sign="onSignInitials(check.name)"
          />
          <pre-enrollment-check-text
            :model="returnModel(check.name)"
            :name="check.name"
            :text="check.text"
          />
          <div class="w-1/12 mb-2 pt-0" v-if="check.extraContent">
            <div v-if="check.name == 'reviewedComplaintProcedure'">
              <span
                v-show="reviewedComplaintProcedureShowContent"
                @click="onShowExtraContent(check.name)"
                class=" cursor-pointer"
              >
                <zondicon icon="cheveron-up" class="h-5" />
              </span>
              <span
                v-show="!reviewedComplaintProcedureShowContent"
                class=" cursor-pointer"
                @click="onShowExtraContent(check.name)"
              >
                <zondicon icon="cheveron-down" class="h-5" />
              </span>
            </div>
            <div v-if="check.name == 'receivedRefundSchedule'">
              <span
                v-show="receivedRefundScheduleShowContent"
                @click="onShowExtraContent(check.name)"
                class=" cursor-pointer"
              >
                <zondicon icon="cheveron-up" class="h-5" />
              </span>
              <span
                v-show="!receivedRefundScheduleShowContent"
                class=" cursor-pointer"
                @click="onShowExtraContent(check.name)"
              >
                <zondicon icon="cheveron-down" class="h-5" />
              </span>
            </div>
            <div v-if="check.name == 'awareProtectionFund'">
              <span
                v-show="awareProtectionFundShowContent"
                @click="onShowExtraContent(check.name)"
                class=" cursor-pointer"
              >
                <zondicon icon="cheveron-up" class="h-5" />
              </span>
              <span
                v-show="!awareProtectionFundShowContent"
                class=" cursor-pointer"
                @click="onShowExtraContent(check.name)"
              >
                <zondicon icon="cheveron-down" class="h-5" />
              </span>
            </div>
          </div>
        </div>
        <div class="flex mt-0" v-if="check.extraContent">
          <div
            class="w-full mb-2 pt-0"
            v-if="
              reviewedComplaintProcedureShowContent &&
                check.name == 'reviewedComplaintProcedure'
            "
          >
            <p class="text-xs md:text-base">
              Student shall first attempt to address the grievance informally
              with the instructor or applicable staff member and try to resolve
              it. If unsuccessful, proceed to the written grievance procedure.
            </p>
            <br />
            <p class="text-xs md:text-base">
              Students may state the grievance in writing to the Chief
              Administrator, Brian Treu through his Administrative Assistant at
              <a
                class="text-blue-600 underline"
                href="mailto:info@phlebotomyusa.com"
                target="_blank"
              >
                info@phlebotomyusa.com
              </a>
              and he will assist you in resolving your concerns. In his absence
              you can contact the Director of Student Services at
              <a
                class="text-blue-600 underline"
                href="mailto:info@phlebotomyusa.com"
                target="_blank"
              >
                info@phlebotomyusa.com </a
              >. They will investigate and address the grievance withing 5
              business days.
            </p>
            <br />
            <!-- <p class="text-xs md:text-base">
              Should Administrator or designee fail to or unacceptable address
              the grievance, they can file a complaint with the Kentucky
              Commission on Proprietary Education. The Student may file a
              complaint by Form PE-24,
              <a
                class="text-blue-600 underline"
                href="http://www.kcpe.ky.gov/forms/FormtoFileaComplaint.pdf"
                target="_blank"
              >
                http://www.kcpe.ky.gov/forms/FormtoFileaComplaint.pdf
              </a>
              , Form to File a Complaint, accompanied, if applicable, by Form
              PE-25,
              <a
                class="text-blue-600 underline"
                href="http://kcpe.ky.gov/forms/ApplicationtoTransferOwnershipofaSchool.pdf"
                target="_blank"
                >http://kcpe.ky.gov/forms/ApplicationtoTransferOwnershipofaSchool.pdf</a
              >
              Authorization for Release of Student Records. Upon receipt of a
              complaint, a copy of the complaint shall be sent to the agent or
              school named in the complaint along with a request for written
              response to the complaint and the time of the complaint committee
              hearing, once established. The agent or school shall file a
              written response with the commission within ten (10) days from the
              date of receipt.
            </p> -->
            <p class="text-xs md:text-base">
              To file a complaint with the Kentucky Commission on Proprietary
              Education, a complaint shall be in writing and shall be filed on
              Form PE-24 May 2022, Form to File a Complaint, accompanied, if
              applicable, by Form PE- 25 May 2022, Authorization for Release of
              Student Records. The form must be mailed to the following address:
              Kentucky Commission on Proprietary Education 500 Mero Street, 4th
              Floor Frankfort, Kentucky 4060 Forms may be located at
              <a
                class="text-blue-600 underline"
                href="https://kcpe.ky.gov/Pages/index.aspx"
                target="_blank"
              >
                https://kcpe.ky.gov/Pages/index.aspx
              </a>
            </p>
          </div>
          <div
            class="w-full mb-2 pt-0"
            v-if="
              receivedRefundScheduleShowContent &&
                check.name == 'receivedRefundSchedule'
            "
          >
            <table class="table-auto">
              <tbody>
                <tr>
                  <td class="border px-4 text-xs md:text-base">
                    Attended up to 4 hours (1 AM/PM Class or ½ Weekend Class)
                  </td>
                  <td class="border px-4 text-xs md:text-base">
                    75% full tuition/fees amount less registration fee
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 text-xs md:text-base">
                    Attended up to 8 hours (2 AM/PM Classes or 1 Weekend Class)
                  </td>
                  <td class="border px-4 text-xs md:text-base">
                    60% full tuition/fees amount less registration fee
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 text-xs md:text-base">
                    Attended up to 12 hours (3 AM/PM Classes or 1½ Weekend
                    Classes)
                  </td>
                  <td class="border px-4 text-xs md:text-base">
                    45% full tuition/fees amount less registration fee
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 text-xs md:text-base">
                    Attended up to 16 hours (4 AM/PM Classes or 2 Weekend
                    Classes)
                  </td>
                  <td class="border px-4 text-xs md:text-base">
                    30% full tuition/fees amount less registration fee
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 text-xs md:text-base">
                    Attended up to 20 hours (5 AM/PM Classes or 2½ Weekend
                    Classes)
                  </td>
                  <td class="border px-4 text-xs md:text-base">
                    15% full tuition/fees amount less registration fee
                  </td>
                </tr>
                <tr>
                  <td class="border px-4 text-xs md:text-base">
                    Attended 24 hours or more
                  </td>
                  <td class="border px-4 text-xs md:text-base">
                    No refund
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="w-full mb-2 pt-0"
            v-if="
              awareProtectionFundShowContent &&
                check.name == 'awareProtectionFund'
            "
          >
            <p class="text-xs md:text-base">
              The Kentucky Commission on Proprietary Education manages a Student
              Protection Fund. The fund shall be used in accordance with KRS
              165A.450. Students can file a claim against the Student Protection
              Fund on Form PE-38,
              <a
                class="text-blue-600 underline"
                href="http://www.kcpe.ky.gov/forms/FormforClaimsAgainsttheStudentProtectionFund.pdf"
                target="_blank"
                >http://www.kcpe.ky.gov/forms/FormforClaimsAgainsttheStudentProtectionFund.pdf</a
              >. The form must be complied in its entirety and accompanied by
              all supporting material and documents.
            </p>
          </div>
        </div>
      </div>
      <div
        class="flex justify-around mt-2 pt-6"
        style="border-bottom: 1px solid black;"
      >
        <div class="text-center self-stretch">
          <div
            class="bg-yellow-300 cursor-pointer p-4"
            @click="onSign"
            v-if="!studentSignature"
          >
            <p class="cursor-pointer text-xs md:text-base">Sign</p>
            <zondicon icon="arrow-thick-down" class="h-5 ml-1" />
          </div>
          <div v-if="studentSignature">
            <p class="student-signature text-base md:text-lg">
              {{ studentSignature }}
            </p>
          </div>
        </div>
        <div class="text-center self-end">
          <p class="text-xs md:text-base">
            {{ new Date() | moment("dddd, MMMM Do YYYY") }}
          </p>
        </div>
      </div>
      <validation-provider name="studentSignature" v-slot="{ errors }">
        <input type="hidden" v-model="studentSignature" />
        <p
          class="mt-1 ml-1 text-red-500 text-xs md:text-sm font-semibold italic"
        >
          {{ errors[0] }}
        </p>
      </validation-provider>
      <next-back-buttons v-on:next="handleSubmit(onSubmit)" v-on:back="back">
      </next-back-buttons>
    </validation-observer>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import NextBackButtons from "./Elements/NextBackButtons.vue";
import PreEnrollmentCheck from "./Elements/PreEnrollmentCheck";
import PreEnrollmentCheckText from "./Elements/PreEnrollmentCheckText";

import { InputFacade } from "vue-input-facade";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { ValidationObserver } from "vee-validate";
import Zondicon from "vue-zondicons";

export default {
  data: () => ({
    receivedInstitucionalCatalogSign: false,
    reviewedDescriptionProgramSign: false,
    understandChargesSign: false,
    receivedRefundScheduleSign: false,
    reviewedComplaintProcedureSign: false,
    awareProtectionFundSign: false,
    receivedCopyEnrollmentSign: false,
    studentSignatureSign: false,
    checks: [
      {
        name: "receivedInstitucionalCatalog",
        signName: "receivedInstitucionalCatalogSign",
        text:
          "Received an institutional catalog including policies on grades, attendance, and conduct.",
        extraContent: false,
      },
      {
        name: "reviewedDescriptionProgram",
        signName: "reviewedDescriptionProgramSign",
        text:
          "Reviewed description of instructional program: (beginning on page 26 of the catalog)",
        extraContent: false,
      },
      {
        name: "understandCharges",
        signName: "understandChargesSign",
        text:
          "Understands all charges: Tuition- $545, Registration Fee -$100, Books/Supplies-$135, National Exam Fee-$115, Total with Exam $895",
        extraContent: false,
      },
      {
        name: "receivedRefundSchedule",
        signName: "receivedRefundScheduleSign",
        text: "Received refund schedule:",
        extraContent: true,
      },
      {
        name: "reviewedComplaintProcedure",
        signName: "reviewedComplaintProcedureSign",
        text: "Reviewed complaint procedure: (pages 22-23 of catalog).",
        extraContent: true,
      },
      {
        name: "awareProtectionFund",
        signName: "awareProtectionFundSign",
        text: "Made aware of Student Protection Fund.",
        extraContent: true,
      },
      {
        name: "receivedCopyEnrollment",
        signName: "receivedCopyEnrollmentSign",
        text: "Received copy of Enrollment Agreement.",
        extraContent: false,
      },
    ],
    initials: "Initials",
    fullNameSignature: "",
    reviewedComplaintProcedureShowContent: true,
    receivedRefundScheduleShowContent: true,
    awareProtectionFundShowContent: true,
  }),
  components: {
    NextBackButtons,
    PreEnrollmentCheck,
    PreEnrollmentCheckText,
    zondicon: Zondicon,
    "input-facade": InputFacade,
    "validation-provider": ValidationProvider,
    "validation-observer": ValidationObserver,
  },
  mounted() {
    this.fullNameSignature = `${this.name} ${this.lastName}`;
    console.log(this.studentSignature);
    if (this.studentSignature) {
      this.studentSignature = `${this.name} ${this.lastName}`;
    }
    //this.studentSignature = `${this.name} ${this.lastName}`;
  },
  computed: {
    ...mapState("formData", ["preEnrollment", "name", "lastName"]),
    getInitials() {
      let fullName = this.name + " " + this.lastName;
      return fullName
        .replace(/[^a-zA-Z- ]/g, "")
        .match(/\b\w/g)
        .join("")
        .toUpperCase();
    },
    receivedInstitucionalCatalog: {
      get() {
        return this.preEnrollment.receivedInstitucionalCatalog;
      },
      set(value) {
        this.updatePreEnrollmentFormField({
          field: "receivedInstitucionalCatalog",
          value,
        });
      },
    },
    reviewedDescriptionProgram: {
      get() {
        return this.preEnrollment.reviewedDescriptionProgram;
      },
      set(value) {
        this.updatePreEnrollmentFormField({
          field: "reviewedDescriptionProgram",
          value,
        });
      },
    },
    understandCharges: {
      get() {
        return this.preEnrollment.understandCharges;
      },
      set(value) {
        this.updatePreEnrollmentFormField({
          field: "understandCharges",
          value,
        });
      },
    },
    receivedRefundSchedule: {
      get() {
        return this.preEnrollment.receivedRefundSchedule;
      },
      set(value) {
        this.updatePreEnrollmentFormField({
          field: "receivedRefundSchedule",
          value,
        });
      },
    },
    reviewedComplaintProcedure: {
      get() {
        return this.preEnrollment.reviewedComplaintProcedure;
      },
      set(value) {
        this.updatePreEnrollmentFormField({
          field: "reviewedComplaintProcedure",
          value,
        });
      },
    },
    awareProtectionFund: {
      get() {
        return this.preEnrollment.awareProtectionFund;
      },
      set(value) {
        this.updatePreEnrollmentFormField({
          field: "awareProtectionFund",
          value,
        });
      },
    },
    receivedCopyEnrollment: {
      get() {
        return this.preEnrollment.receivedCopyEnrollment;
      },
      set(value) {
        this.updatePreEnrollmentFormField({
          field: "receivedCopyEnrollment",
          value,
        });
      },
    },
    studentSignature: {
      get() {
        return this.preEnrollment.studentSignature;
      },
      set(value) {
        this.updatePreEnrollmentFormField({
          field: "studentSignature",
          value,
        });
      },
    },
  },
  methods: {
    ...mapMutations("formData", ["updatePreEnrollmentFormField"]),
    onSign() {
      this.studentSignature = this.fullNameSignature;
      this.studentSignatureSign = true;
    },
    onSignInitials(field) {
      const fieldSigned = `${field}Sign`;
      console.log(fieldSigned);
      this[field] = true;
      this[fieldSigned] = true;
    },
    returnModel(model) {
      //this.$nextTick(() => {
      return this[model];
      //})
    },
    returnShowExtraContent(model) {
      const extraContent = `${content}ShowContent`;
      return this[extraContent];
    },
    onShowExtraContent(content) {
      console.log(content);
      const extraContent = `${content}ShowContent`;
      this[extraContent] = !this[extraContent];
      console.log(this[extraContent]);
    },
    async onSubmit() {
      //this.$emit('next');

      try {
        const { fields } = this.$refs.formPreEnrollment;
        let valid = true;
        //console.log(this.$refs.formPreEnrollment);
        //Object.keys();
        for (let field of Object.keys(fields)) {
          console.log(this[field]); // John, then 30
          console.log(field); // John, then 30
          const value = this[field];
          if (!value) {
            await this.$refs.formPreEnrollment.setErrors({
              [field]: ["This field must be signed"],
            });
            valid = false;
          }
        }

        console.log(valid);

        if (valid) {
          this.$emit("next");
        }

        // if(errors.email.length == 0){
        //   this.$emit('next');
        // }
        // else{
        //   await this.$refs.formBasic.setErrors({
        //     email: ['This email is already taken']
        //   });
        // }
      } catch (error) {
        console.log(error);
      }
    },
    back() {
      this.$emit("back");
    },
  },
};
</script>

<style scoped>
p.student-signature {
  font-family: "Cedarville Cursive";
}
span.student-signature {
  font-family: "Cedarville Cursive";
  /*font-size:12pt;*/
}
.cursor-pointer {
  cursor: pointer;
}
</style>
