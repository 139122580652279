<template>
  <div class="relative pt-1 ">
    <div class="flex mb-2 items-center justify-center">
      <div>
        <span
          class="text-xs font-semibold inline-block py-1 px-2 uppercase bg-green-300 text-green-ecg rounded-full"
        >
          <!-- {{ (step > numberSteps) ? "Complete" : "Step " + step + "/" + numberSteps }} -->
        <span v-if="(step < (numberSteps + 1)) || ( envelopeId && (step == (numberSteps + 1)) )">
          {{ "Step " + step + "/" + (numberSteps + 1) }}
        </span>
        <span v-if="!envelopeId && (step == (numberSteps + 1))">
          {{ "Complete" }}
        </span>
        </span>
      </div>
    </div>
    <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-300">
      <div
        ref="progressbar-bar"

        v-bind:style="styleBar"
        class="shadow-none flex flex-col text-center bg-green-ecg whitespace-nowrap text-white justify-center transition-all duration-300"
      ></div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
  props: {
    step: Number,
    numberSteps: Number
  },
  computed: {
    ...mapState('courseInformation', ['courseTypeId']),
    ...mapState('responseData', ['envelopeId']),
    styleBar: function() {
      return { width: this.barPercentage + "%"};
    },
    barPercentage: function() {
      return ( (this.step) / (this.numberSteps + 1) ) * 100;
    },
  }
}
</script>

<style lang="css" scoped>
</style>
