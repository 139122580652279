<template>
  <div class="">
    <!-- Desktop Buttons -->
    <div class="hidden md:flex mt-10 mb-10">
      <div v-if="hasBackButton" class="flex-1">
        <button
          @click="back"
          type="button"
          class="underline focus:outline-none font-semibold py-2 px-4"
          :class="getDesktopBackButtonColor"
        >
          {{ backText }}
        </button>
      </div>
      <div class="flex-1">
        <div v-if="hasNextButton" class="flex justify-end">
          <button
            @click="onSubmit"
            type="submit"
            :class="getNextButtonColor"
            class="focus:outline-none ml-6 text-white font-bold py-3 px-8 rounded"
            :disabled="loading"
          >
            <moon-loader
              class="px-4"
              :loading="loading"
              color="#FFF5F5"
              size="25px"
            ></moon-loader>
            <p v-if="!loading">{{ nextText }}</p>
          </button>
        </div>
      </div>
    </div>
    <!-- End Desktop Buttons -->
    <!-- Buttons Mobile -->
    <div class="md:hidden mt-10 mb-10">
      <div v-if="hasNextButton" class="flex mb-6">
        <button
          @click="onSubmit"
          type="submit"
          :class="getNextButtonColor"
          class="w-full focus:outline-none text-white font-bold py-4 rounded-lg"
          :disabled="loading"
        >
          <div class="flex justify-center">
            <moon-loader
              class=""
              :loading="loading"
              color="#FFF5F5"
              size="25px"
            ></moon-loader>
            <p v-if="!loading">{{ nextText }}</p>
          </div>
        </button>
      </div>
      <div v-if="hasBackButton" class="">
        <button
          @click="back"
          type="button"
          class="w-full border-2 rounded-lg focus:outline-none font-semibold py-2 px-4"
          :class="getMobileBackButtonColor"
        >
          <div class="flex justify-center py-2">
            <zondicon icon="cheveron-left" class="h-5 fill-current" />
            <p class="ml-2">{{ backText }}</p>
          </div>
        </button>
      </div>
    </div>
    <!-- End Buttons Mobile -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { MoonLoader } from "vue-spinner/dist/vue-spinner.min.js";

import Zondicon from "vue-zondicons";

export default {
  components: {
    "moon-loader": MoonLoader,
    zondicon: Zondicon,
  },
  props: {
    hasBackButton: {
      type: Boolean,
      default: true,
    },
    hasNextButton: {
      type: Boolean,
      default: true,
    },
    backText: {
      type: String,
      default: "Go Back",
    },
    nextText: {
      type: String,
      default: "Next",
    },
    nextBlocked: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("courseInformation", ["courseTypeId"]),
    getDesktopBackButtonColor() {
      return this.courseTypeId == 4
        ? "text-blue-800 hover:text-blue-600"
        : this.courseTypeId === 3
        ? "text-green-ecg hover:text-green-600"
        : "text-red-800 hover:text-red-600";
    },
    getNextButtonColor() {
      if (this.nextBlocked) {
        return this.courseTypeId == 4
          ? "opacity-50 cursor-not-allowed bg-blue-800 hover:bg-blue-900"
          : this.courseTypeId === 11
          ? "opacity-50 cursor-not-allowed bg-green-ecg hover:bg-green-900"
          : "opacity-50 cursor-not-allowed bg-red-800 hover:bg-red-900";
      }
      return this.courseTypeId == 4
        ? "bg-blue-800 hover:bg-blue-900"
        : this.courseTypeId === 11
        ? "bg-green-ecg hover:bg-green-900"
        : "bg-red-800 hover:bg-red-900";
    },
    getMobileBackButtonColor() {
      return this.courseTypeId == 4
        ? "border-blue-200 hover:bg-blue-200 hover:border-blue-300 text-blue-800 hover:text-blue-900"
        : this.courseTypeId === 11
        ? "border-green-200 hover:bg-green-200 hover:border-green-300 text-green-ecg hover:text-green-ecg bg-green-ecg hover:bg-green-900"
        : "border-red-200 hover:bg-red-200 hover:border-red-300 text-red-800 hover:text-red-900";
    },
    // getMobileNextButtonColor(){
    //   if(this.nextBlocked){
    //     return this.courseTypeId == 4 ?
    //     'opacity-50 cursor-not-allowed bg-blue-800 hover:bg-blue-900' :
    //     'opacity-50 cursor-not-allowed bg-red-800 hover:bg-red-900';
    //   }
    //   return this.courseTypeId == 4 ? 'bg-blue-800 hover:bg-blue-900' : 'bg-red-800 hover:bg-red-900';
    // },
  },
  methods: {
    onSubmit: function() {
      this.$emit("next");
    },
    back: function() {
      this.$emit("back");
    },
  },
};
</script>

<style scoped></style>
