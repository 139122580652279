var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ ..._vm.formCssClasses('paragraph') }},[_c('h4',{staticClass:"mt-6 text-lg",class:{
        ..._vm.formCssClasses('paragraph')
      }},[_vm._v(" Attire ")]),_c('message-attaire'),_c('h4',{staticClass:"mt-6 text-lg",class:{
        ..._vm.formCssClasses('paragraph')
      }},[_vm._v(" Punctuality ")]),_c('p',{staticClass:"mt-2"},[_vm._v(" We encourage you to be on time and if possible 10 minutes early on the first class. ")]),_c('h4',{staticClass:"mt-6 text-lg",class:{
        ..._vm.formCssClasses('paragraph')
      }},[_vm._v(" Payments ")]),_c('p',{staticClass:"mt-2"},[_vm._v(" To make payments please log into your account "),_c('a',{staticClass:"hover:text-blue-700 text-blue-500 underline font-bold",attrs:{"href":_vm.link,"target":"_blank"}},[_vm._v("here")]),_vm._v(" or contact student services at "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.phlebsPhoneNumber)+".")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }